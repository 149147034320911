import { addYears, parseISO, isWithinInterval, startOfMonth } from 'date-fns';
import {
  phone as phoneRegex,
  address as addressRegex,
  email as emailRegex,
  regexRemoveStartsAndEndsWith
} from 'lib/valRegexes';
import { formatCurrency } from 'lib/formatters';

export const withdrawAmount = (value) => {
  if (Number.isNaN(Number(value))) {
    return false;
  } else if (value < 10) {
    return `Minimum amount ${formatCurrency(10)}`;
  } else if (value > 100_000) {
    return `Maximum amount ${100_000}`;
  } else {
    return true;
  }
};

/**
 * Use for non-deposit phone validation
 * @param value
 * @returns {*}
 */
export const phone = (value) => {
  const isValid = phoneRegex.test(value);

  if (!isValid) return false;

  if (__ENV__.MRQ_COUNTRY === 'UK') {
    if (
      value.startsWith('07') ||
      value.startsWith('+447') ||
      value.startsWith('00447') ||
      value.startsWith('447')
    ) {
      return true;
    }
  } else {
    return true;
  }

  return { warn: 'This looks like a landline. We can only verify mobile numbers.' };
};

/**
 * Use for deposit phone validation
 * @param value
 * @returns {*}
 */
export const mobilePhone = (value) => phone(value) === true;
export const promotionCode = (value) => value.length < 50;
export const securityCode = (value) => value.toString().length === 3;
export const firstOrLastName = (value) =>
  (!!value.length && value.length < 200) || 'Please enter a valid name';
export const phoneCode = (value) => value.toString().length === 4;
export const paymentMethod = (value) => !!value;
export const checked = (value) => !!value;
export const validatePasswordConfirm = (value, { password: passwordField }) =>
  value === passwordField.value ? ['password'] : "Passwords don't match";
export const birthdayOver18 = (dateString) =>
  addYears(parseISO(dateString), 18) <= Date.now() || 'You must be over 18 to register';
export const hasDuplicateValues = (fieldName, value, fields) =>
  Object.keys(fields).some((key) => key !== fieldName && fields[key].value === value);

export const expirationDate = (dateString) => {
  if (!dateString || dateString.length === 8) {
    return false;
  }

  if (dateString.length >= 7) {
    const month = dateString.split(' / ')[0];
    if (month > 12 || month < 1) return false;

    const fullYear =
      dateString.length === 7 ? `20${dateString.split(' / ')[1]}` : dateString.split(' / ')[1];
    const thisMonth = startOfMonth(new Date());
    const in10Years = addYears(thisMonth, 10);

    return isWithinInterval(new Date(fullYear, month - 1), {
      start: thisMonth,
      end: in10Years
    });
  }

  return false;
};

/**
 * Checks if address line 1 is required before validating address line 2
 * @param value {string} This is the value of address line 2
 * @param fields
 * @param [fields.address] This is the value of address line 1
 * @param [fields.address.required] {bool}
 * @returns {bool}
 */
export const addressLine2 = (value, { address }) =>
  !address.required || (address.required && addressRegex.test(value));

/**
 * Checks if message string contains any PII (personal identifyable information)
 * and masks it
 * @param message {string} This is incoming message
 * @returns {string} This is the sanitized message with masked information
 */
export const sanitizePiiMessage = (message) => {
  const emailAnywhereRegex = regexRemoveStartsAndEndsWith(emailRegex);
  const phoneAnywhereRegex = regexRemoveStartsAndEndsWith(phoneRegex);
  return message
    .replace(emailAnywhereRegex, '<masked email>')
    .replace(phoneAnywhereRegex, '<masked phone>');
};
