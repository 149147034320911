import { flowRight } from 'lodash';
import { createSelector } from 'reselect';
import { isLoading } from 'lib/redux-utils';
import { NAME, kyc, kycMessages } from './constants';
import * as m from './model';
import { AT } from './actionTypes';

export const model = (state) => state[NAME] || m.initialState;

export const getReferees = flowRight([m.referees, model]);
export const getRefereeRefs = flowRight([m.refereeRefs, model]);
export const getFreeSpinsEarned = flowRight([m.freeSpinsEarned, model]);
export const getFreeSpinsPending = flowRight([m.freeSpinsPending, model]);
export const getDisabledTabs = flowRight([m.disabledTabs, model]);
export const getRewards = flowRight([m.rewards, model]);
export const getDailyFreeGames = flowRight([m.dailyFreeGames, model]);
export const getDailyFreeGameRefs = flowRight([m.dailyFreeGameRefs, model]);
export const getDailyFreeGamesLoaded = flowRight([m.dailyFreeGamesLoaded, model]);
export const getSlotRewardsLoaded = flowRight([m.slotRewardsLoaded, model]);
export const getRewardOffers = flowRight([m.rewardOffers, model]);
export const getRewardsCount = flowRight([m.rewardsCount, model]);
export const getUsername = flowRight([m.username, model]);
export const getFreeRounds = flowRight([m.freeRounds, model]);
export const getRequiresVerification = flowRight([m.requiresVerification, model]);
export const getVerificationStatus = flowRight([m.verificationStatus, model]);
export const getVerificationType = flowRight([m.verificationType, model]);
export const getVerificationProvider = flowRight([m.verificationProvider, model]);
export const getLiveChatUnreadCount = flowRight([m.liveChatUnreadCount, model]);
export const getIsLiveChatLoaded = flowRight([m.liveChatLoaded, model]);
export const getIsLauncherVisible = flowRight([m.isLauncherVisible, model]);

export const getHasLoadedAllRewards = createSelector(
  [getDailyFreeGamesLoaded, getSlotRewardsLoaded],
  (dfgs, slotRewards) => dfgs && slotRewards
);

export const getRewardsExist = createSelector(
  [getRewards, getDailyFreeGameRefs],
  (rewards, dailyFreeGames) => rewards.length > 0 || dailyFreeGames.length > 0
);

export const getAllRewardsLoading = (state) =>
  isLoading(state, AT.GET_REWARDS._) || isLoading(state, AT.GET_DAILY_FREE_GAMES._);

export const getNetDeposits = flowRight([m.netDeposits, model]);
export const getNetBets = flowRight([m.netBets, model]);

export const getVerifiedPhoneNumber = (state) =>
  model(state).phoneVerified && model(state).phoneNumber;

export const getUnverifiedPhoneNumber = (state) =>
  !model(state).phoneVerified && model(state).phoneNumber;

export const getFullName = (state) => `${model(state).firstName} ${model(state).lastName}`;

export const getKycStatus = (state) => {
  const status = model(state).kycStatus;
  const providers = model(state).kycProviders;
  const hasProviders = providers?.length > 0;

  if (status === kyc.PASS) {
    return kyc.PASS;
  }
  if (!providers) {
    return null;
  }
  if (status === kyc.FAIL && hasProviders) {
    return kyc.REFER;
  }
  if (status === kyc.REFER && !hasProviders) {
    return kyc.FAIL;
  }

  return status;
};

export const getFailedKycNotification = (kycStatus) => {
  if (kycStatus === kyc.PENDING) {
    return kycMessages.PENDING;
  } else if (kycStatus === kyc.NONE) {
    return kycMessages.NONE_NOTIFICATION;
  } else if (kycStatus === kyc.REFER) {
    return kycMessages.REFER_NOTIFICATION;
  } else if (kycStatus === kyc.FAIL) {
    return kycMessages.FAIL_NOTIFICATION;
  } else {
    return false;
  }
};

export const homeAddress = flowRight([m.homeAddress, model]);
export const getAvatar = flowRight([m.avatar, model]);
export const getRealityCheckInterval = flowRight([m.realityCheckInterval, model]);
export const getKycVerificationDocuments = flowRight([m.kycVerificationDocuments, model]);
