import React, { memo } from 'react';
import type { LoadableComponent } from '@loadable/component';
import { useAppSelector } from 'hooks/redux';
import Lobby from 'modules/Lobby';

type LoadableWithMapping = LoadableComponent<any> & {
  mapping?: Mapping;
};

export type Mapping = Record<string, LoadableWithMapping>;

interface BuilderProps {
  mapping: Mapping;
  accessRef: string;
  position?: number;
}

const Builder = ({ mapping, accessRef, position }: BuilderProps) => {
  const entity = useAppSelector((state) => Lobby.selectors.getEntityByKey(state, accessRef));

  if (!entity) {
    return null;
  }

  // Type guard checks if the entity does NOT have a 'component' property
  if (!('component' in entity)) {
    // eslint-disable-next-line no-console -- warning message
    console.warn('Entity is not a component type');
    return null;
  }

  const { component, refName } = entity;
  const Component = mapping[component];

  if (!Component) {
    // eslint-disable-next-line no-console -- warning message
    console.warn(`No component mapped for type: ${component}`);
    return null;
  }

  return <Component key={refName} entity={entity} mapping={mapping} position={position} />;
};

export default memo(Builder);
