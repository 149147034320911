import type { ComponentProps } from 'react';
import React, { Children, cloneElement } from 'react';
import makeModal from 'components/Modals';
import type { PANEL_CORNER } from 'lib/edgePanel';
import { PANEL_CORNER_SIDE } from 'lib/edgePanel';
import './edgePanel.css';

interface EdgePanelProps {
  template: JSX.Element;
}
const EdgePanel = (props: EdgePanelProps) => {
  const { template, ...rest } = props;
  return (
    <div className="edgePanel__content">
      {cloneElement(Children.only(template), {
        ...rest,
        ...template.props
      })}
    </div>
  );
};

export const makeEdgePanel = ({
  panelCorner,
  name,
  className,
  swipeable,
  // swipeableHysteresis lower than default (0.6) for EdgePanel
  // since swipeable__before in Swipeable component takes entire page size
  // https://react-swipeable-views.com/api/api/
  swipeableHysteresis = 0.2,
  centered
}: {
  panelCorner: PANEL_CORNER;
  name: string;
  className: string;
  swipeable: boolean;
  swipeableHysteresis?: number;
  centered?: boolean;
}) => {
  const panelSide = PANEL_CORNER_SIDE[panelCorner];

  const modal = makeModal(
    name,
    swipeable
      ? null
      : {
          name: `modal-slideEdgeFrom-${panelSide}`,
          timeout: 400, // synced with the animation transition in modals.css
          component: 'div'
        },
    {
      swipeable,
      className: `${className} ${centered ? 'edgePanel--centered' : ''}`,
      edgePanel: panelCorner,
      swipeableHysteresis,
      swipeableIgnoreNativeScroll: true // needed to be true when component: 'div'
      // (https://react-swipeable-views.com/api/api/)
    }
  ) as unknown as (props: ComponentProps<any>) => React.ComponentType<any>;
  return modal(EdgePanel);
};
