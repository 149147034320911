import type { FluxStandardAction } from 'redux-promise-middleware';
import { Identify, Revenue } from '@amplitude/analytics-browser';
import { startCase } from 'lodash';
import Cookies from 'js-cookie';
import amplitude from 'lib/analytics';
import type { SlotTile } from 'lib/LobbyEntities';
import { actionTypes as UI } from 'modules/UI';
import UserModule from 'modules/User';
import AnalyticsModule from 'modules/Analytics';
import WalletModule from 'modules/Wallet';
import CarouselModule from 'modules/Carousel';
import AuthModule from 'modules/Auth';
import type { types as AuthModuleTypes } from 'modules/Auth';
import type { types as AnalyticsModuleTypes } from 'modules/Analytics';
import type { Section } from 'modules/Lobby';

interface TypedFSA extends FluxStandardAction {
  type: string;
}

interface TypedFSAWithPayload extends TypedFSA {
  type: string;
  payload: any;
}

type Effect<T = TypedFSA> = (
  action: undefined extends T ? TypedFSA : TypedFSAWithPayload
) => unknown;

interface CopyAction extends TypedFSA {
  payload?: string;
}

export interface ExperimentExposure extends TypedFSAWithPayload {
  payload: {
    /** The flag or experiment key which the user is being exposed to */
    flag: string;
    /**
     * The variant for the flag or experiment that the user has been exposed to. If null or missing, the user
     * property for the flag/experiment is unset, and the user is no longer a part of the experiment.
     */
    variant?: string;
    /**
     * The **key** of the experiment that the user was exposed to. The experiment key is used to differentiate
     * between two runs of an experiment on the same flag key
     */
    experiment?: string;
  };
}

export interface ModalOpened extends TypedFSAWithPayload {
  payload: {
    name?: string;
    method?: string; // For distinguishing between depossit method providers in Amplitude.
    // Utilized only by the modal that opens the deposit methods.
    // This distinguishes between the different deposit method providers since all of them are
    // opened by the same modal ("addPayment"). Creating separate modal for each deposit method provider is too much work.
  };
}

export interface ModalClosed extends TypedFSAWithPayload {
  payload: {
    name?: string;
    method?: string;
  };
}

export interface GameLaunched extends TypedFSAWithPayload {
  payload: {
    refName: string;
    name?: string;
    provider?: string;
    platform?: string;
    searchTerm?: string;
    section?: Section;
    source?: 'Dynamic lobby' | 'Search' | 'Rewards' | 'View all' | 'Lobby';
    category?: string;
    position?: number;
    shape?: SlotTile['shape'];
    listRef?: string;
    listPosition?: number;
  };
}

export interface SectionScrolled extends TypedFSAWithPayload {
  payload: {
    refName: string;
    type: 'Dynamic lobby';
    name?: string;
    position?: number;
    category?: string;
    section?: string;
  };
}

export const OfferClickedSourceType = {
  Rewards: 'Rewards',
  Lobby: 'Lobby'
} as const;
export type OfferClickedSourceType =
  (typeof OfferClickedSourceType)[keyof typeof OfferClickedSourceType];
export const OfferClickedInteractionType = {
  CardClick: 'Card Click',
  PrimaryCTAClick: 'Primary CTA Click',
  SecondaryCTAClick: 'Secondary CTA Click',
  ModalCTAClick: 'Modal CTA Click'
} as const;
export type OfferClickedInteractionType =
  (typeof OfferClickedInteractionType)[keyof typeof OfferClickedInteractionType];
export interface OfferClicked extends TypedFSAWithPayload {
  payload: {
    refName: string;
    type: string;
    source: OfferClickedSourceType;
    interactionType: OfferClickedInteractionType;
  };
}

interface FormFieldsError {
  /** Field name with errors */
  field: string;
  /** Error message for form field */
  msg: string;
}

export interface RegistrationRejected extends TypedFSAWithPayload {
  payload: {
    /** User email */
    email?: string;
    /** What kind of actions should display to user */
    prompt?: AuthModuleTypes.AccessDeniedPrompt;
  };
  meta?: {
    errors?: FormFieldsError[];
    /** Error message */
    msg: string;
  };
}

export interface AddressInputModeChanged extends TypedFSAWithPayload {
  payload: {
    isManualAddress: boolean;
  };
}

export interface AddressOptionSelected extends TypedFSAWithPayload {
  payload: {
    // Only 'Address' is defined by Loqate, the others are 'Container' types which
    // means it can be any field as long as it leads to another list of addresses
    // https://www.loqate.com/developers/api/Capture/Interactive/Find/1.10/
    addressType: string;
    addressValue: string;
  };
}

export interface IdentifyAction extends TypedFSAWithPayload {
  payload: AnalyticsModuleTypes.TrackedProfile;
}

interface UserRegisteredAction extends TypedFSAWithPayload {
  payload: {
    ref: string;
    regPromoCode?: string;
    affiliate?: AnalyticsModuleTypes.Affiliate;
  };
}

const copy = ({ payload = '' }: CopyAction) => {
  // This action is not critical, so not showing users any feedback on error
  navigator.clipboard.writeText(payload).catch((e) => {
    // Check if user allows clipboard permissions otherwise rethrow to capture in Sentry
    if (!(e instanceof DOMException && e.name === 'NotAllowedError')) {
      throw e;
    }
  });
};

// We use the Effects middleware for Amplitude analytics instead of redux-beacon as it is simpler
// and provides better types. This will be especially important when we use "ampli", which is the
// auto generated typed wrapper over the Amplitude SDK.
const identify: Effect = ({ payload }: IdentifyAction) => {
  const event = new Identify()
    .set('Username', payload.username)
    .set('KYC Status', payload.kycStatus)
    .set('Birth Date', payload.birthDate)
    .set('Phone Verified', payload.phoneVerified);

  if (payload.affiliate?.affid) {
    event.set('Affiliate ID', payload.affiliate.affid);
  }
  if (payload.affiliate?.resource) {
    event.set('Affiliate Campaign Resource', payload.affiliate.resource);
  }
  if (payload.affiliate?.source) {
    event.set('Affiliate Source', payload.affiliate.source);
  }
  if (payload.affiliate?.site) {
    event.set('Affiliate Site', payload.affiliate.site);
  }

  amplitude.setUserId(payload.userRef);
  amplitude.identify(event);
};

const login: Effect = ({ payload }) => {
  amplitude.setUserId(payload.refName);
  amplitude.track('User Authenticated');
};

const setKYC: Effect = ({ payload }) => {
  const event = new Identify().set('KYC Status', payload.kycStatus);

  amplitude.identify(event);
};

const depositSuccess: Effect = ({ payload }) => {
  const event = new Revenue()
    .setProductId('Web Deposit')
    .setQuantity(1)
    .setPrice(parseFloat(payload.amount))
    // TODO: Backend inconsistency with where the information is located in the payload MRQ-1030
    .setRevenueType(payload.type || payload.paymentMethodType)
    .setEventProperties({
      'Mobile Network': payload.operator,
      'Payment Provider': payload.provider,
      'Deposit Count': payload.currentCount
    });

  amplitude.revenue(event);
};

const carouselTop: Effect = ({ payload }) => {
  amplitude.track('Carousel Tab Selected', {
    'Carousel Name': payload.name,
    'Tab Type': 'Top',
    'Tab ID': payload.tabRef
  });
};

const carouselBottom: Effect = ({ payload }) => {
  amplitude.track('Carousel Tab Selected', {
    'Carousel Name': payload.name,
    'Tab Type': 'Bottom',
    'Tab ID': payload.tabRef
  });
};

/**
 * https://www.docs.developers.amplitude.com/experiment/general/experiment-event-tracking/#exposure-event-definition
 */
const exposeExperiment: Effect = ({ payload }: ExperimentExposure) => {
  const isExcluded = JSON.parse(Cookies.get('experiment_exclude') || 'false');
  const variant = isExcluded ? null : payload.variant;
  if (variant) {
    // We shouldn't send an exposure event for no variant https://www.docs.developers.amplitude.com/experiment/guides/troubleshooting/exposures-without-assignments/
    amplitude.track('$exposure', {
      variant,
      experiment: payload.experiment,
      flag_key: payload.flag
    });
  } else if (isExcluded && payload.flag) {
    // Mark user for exclusion from experiment
    const identifyEvent = new Identify();
    identifyEvent.postInsert('Excluded Flags', payload.flag);
  }
};

/**
 * Remove user ID but don't reset device ID. Amplitude's reset() function resets both.
 */
const logout: Effect = () => {
  amplitude.setTransport('beacon');
  amplitude.setUserId(undefined);
  amplitude.flush();
};

/**
 * Game card, tile, etc. clicked
 */
const gameLaunched: Effect = ({ payload }: GameLaunched) => {
  amplitude.track('Game Launched', {
    // Tracking these explicitly so that they're added on creation, not on SDK "flush", which
    // comes later and can show outdated urls
    '[Amplitude] Page Location': window.location.href,
    '[Amplitude] Page Path': window.location.pathname,
    'Game ID': payload.refName,
    'Game Name': payload.name,
    'Game Provider': payload.provider,
    'Game Platform': payload.platform,
    'Game Section': startCase(payload.section),
    'Game Launch Source': payload.source,
    'Game Category': payload.category,
    'Game Position': payload.position,
    'Game Tile Type': payload.shape,
    'Game List ID': payload.listRef,
    'Game List Position': payload.listPosition,
    'Search Term': payload.searchTerm
  });
};

/**
 * Explicit tracking for the modals.
 */

const modalOpened: Effect = ({ payload }: ModalOpened) => {
  amplitude.track('Modal Opened', {
    // Tracking these explicitly so that they're added on creation, not on SDK "flush", which
    // comes later and can show outdated urls
    '[Amplitude] Page Location': window.location.href,
    '[Amplitude] Page Path': window.location.pathname,
    'Modal Name': payload.name,
    'Payment Method': payload.method
  });
};

const modalClosed: Effect = ({ payload }: ModalClosed) => {
  amplitude.track('Modal Closed', {
    // Tracking these explicitly so that they're added on creation, not on SDK "flush", which
    // comes later and can show outdated urls
    '[Amplitude] Page Location': window.location.href,
    '[Amplitude] Page Path': window.location.pathname,
    'Modal Name': payload.name,
    'Payment Method': payload.method
  });
};

/**
 * First scroll interaction with a section (e.g. dynamic lobby game list)
 */
const sectionScrolled: Effect = ({ payload }: SectionScrolled) => {
  amplitude.track('Section Scrolled', {
    'Section ID': payload.refName,
    'Section Type': payload.type,
    'Section Name': payload.name,
    'Section Position': payload.position,
    'Game Section': payload.section,
    'Game Category': payload.category
  });
};

/**
 * User has registered successfully
 */
const userRegistered: Effect = ({ payload }: UserRegisteredAction) => {
  const identifyEvent = new Identify();
  amplitude.setUserId(payload.ref);
  if (payload.regPromoCode) {
    identifyEvent.set('Registration Promo Code', payload.regPromoCode.toLowerCase());
  }
  if (payload.affiliate?.affid) {
    identifyEvent.set('Registration Affiliate ID', payload.affiliate.affid);
  }
  if (payload.affiliate?.resource) {
    identifyEvent.set('Registration Affiliate Campaign Resource', payload.affiliate.resource);
  }
  if (payload.affiliate?.source) {
    identifyEvent.set('Registration Affiliate Source', payload.affiliate.source);
  }
  if (payload.affiliate?.site) {
    identifyEvent.set('Registration Affiliate Site', payload.affiliate.site);
  }
  amplitude.identify(identifyEvent);
  amplitude.track('User Registered');
};

const trackRegistrationRejected = (eventName: string, { payload, meta }: RegistrationRejected) =>
  amplitude.track(eventName, {
    'User Email': payload?.email,
    'User Action': payload?.prompt,
    'Error Message': meta?.msg,
    Errors: meta?.errors
  });

/**
 * User is blocked during registration because was flagged as duplicated
 */
const partialRegistrationRejected: Effect = (action: RegistrationRejected) =>
  trackRegistrationRejected('Partial Registration Rejected', action);

/**
 * User is blocked from registration because was flagged as duplicated
 */
const registrationRejected: Effect = (action: RegistrationRejected) =>
  trackRegistrationRejected('Registration Rejected', action);

/**
 * User switched between entering the address manually and using the search feature
 */
const addressInputModeChanged: Effect = ({ payload }: AddressInputModeChanged) => {
  amplitude.track('Address Input Mode Changed', {
    'Address Input Mode': payload.isManualAddress ? 'Manual' : 'Loqate'
  });
};

/**
 * User opened the autocomplete address dropdown via the search feature
 */
const addressDropdownExpanded: Effect = () => {
  amplitude.track('Address Expanded');
};

/**
 * User selected an address via the autocomplete address dropdown
 */
const addressDropdownOptionSelected: Effect = ({ payload }: AddressOptionSelected) => {
  amplitude.track('Address Option Selected', {
    'Address Type': payload.addressType,
    'Address Value': payload.addressValue
  });
};

/**
 * Offer card, Offer CTA, etc. clicked
 */
const offerClicked: Effect = ({ payload }: OfferClicked) => {
  amplitude.track('Offer Clicked', {
    'Offer ID': payload.refName,
    'Offer Type': payload.type,
    'Offer Source': payload.source,
    'Interaction Type': payload.interactionType
  });
};

const effectMap: Record<string, Effect> = {
  [WalletModule.actionTypes.AT.DEPOSIT_CARD_BRAINTREE.FULFILLED]: depositSuccess,
  [WalletModule.actionTypes.AT.DEPOSIT_MOBILE.FULFILLED]: depositSuccess,
  [WalletModule.actionTypes.AT.DEPOSIT_ECOSPEND_FIRST.FULFILLED]: depositSuccess,
  [WalletModule.actionTypes.AT.DEPOSIT_ECOSPEND.FULFILLED]: depositSuccess,
  [WalletModule.actionTypes.AT.DEPOSIT_PAYPAL.FULFILLED]: depositSuccess,
  [WalletModule.actionTypes.AT.DEPOSIT_CARD_PCI_PROXY.FULFILLED]: depositSuccess,
  [AuthModule.AT.REGISTER.FULFILLED]: userRegistered,
  [AuthModule.AT.PARTIAL_REGISTER.REJECTED]: partialRegistrationRejected,
  [AuthModule.AT.REGISTER.REJECTED]: registrationRejected,
  [CarouselModule.actionTypes.SELECT_TOP]: carouselTop,
  [CarouselModule.actionTypes.SELECT_BOTTOM]: carouselBottom,
  [UserModule.actionTypes.AT.KYC.FULFILLED]: setKYC,
  [AnalyticsModule.T.PROFILE]: identify,
  [AnalyticsModule.T.LOGIN]: login,
  [AnalyticsModule.T.EXPOSE_EXPERIMENT]: exposeExperiment,
  [AnalyticsModule.T.LOGGED_OUT]: logout,
  [AnalyticsModule.T.GAME_LAUNCHED]: gameLaunched,
  [AnalyticsModule.T.SECTION_SCROLLED]: sectionScrolled,
  [AnalyticsModule.T.OFFER_CLICKED]: offerClicked,
  [AnalyticsModule.T.MODAL_OPENED]: modalOpened,
  [AnalyticsModule.T.MODAL_CLOSED]: modalClosed,
  [AnalyticsModule.T.ADDRESS_INPUT_MODE_CHANGED]: addressInputModeChanged,
  [AnalyticsModule.T.ADDRESS_EXPANDED]: addressDropdownExpanded,
  [AnalyticsModule.T.ADDRESS_OPTION_SELECTED]: addressDropdownOptionSelected,
  [UI.COPY]: copy
};

export default effectMap;
