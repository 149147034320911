import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Api from 'services/Api';
import makeModal from 'components/Modals';
import Modals from 'modules/Modals';
import User, { constants as UserConstants } from 'modules/User';
import Wallet from 'modules/Wallet';
import 'components/SideMenu/menu.css';
import UserMenuContent from './UserMenuContent';

const getTransitionType = () =>
  window.innerWidth >= 1400
    ? { name: 'modal-fade' }
    : { name: 'modal-slideEdgeFrom-right', timeout: 400 };

const UserMenuAdapter = ({ close }: { close: () => void }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const fullName = useAppSelector((state) => User.selectors.getFullName(state));
  const freeRounds = useAppSelector((state) => User.selectors.getFreeRounds(state) as number);
  const requiresVerification = useAppSelector(
    (state) => User.selectors.getRequiresVerification(state) as boolean
  );
  const verificationStatus = useAppSelector(
    (state) =>
      User.selectors.getVerificationStatus(state) as UserConstants.VerificationStatus | null
  );
  const verificationType = useAppSelector<UserConstants.VerificationType>(
    User.selectors.getVerificationType
  );
  const globalJackpotAmount = useAppSelector(
    (state) => Wallet.selectors.getGlobalJackpotAmount(state) as number
  );

  const openDeposit = useCallback(() => {
    close();
    dispatch(Modals.actions.open('deposit'));
  }, [close, dispatch]);
  const openReferAfriend = useCallback(() => {
    close();
    dispatch(Modals.actions.open('referAFriend'));
  }, [close, dispatch]);
  const handleVerifyAccountClick = useCallback(() => {
    if (verificationType === UserConstants.VerificationType.EDD) {
      history.push('/secure/user/account/verification');
    } else {
      dispatch(Modals.actions.open('KYC'));
    }
    close();
  }, [close, dispatch, history, verificationType]);
  const openWithdraw = useCallback(() => {
    close();
    dispatch(Modals.actions.open('withdraw'));
  }, [close, dispatch]);
  const logout = useCallback(() => {
    dispatch(Modals.actions.close('userMenu'));
    void Api.actions.auth.logout(null, new FormData())(dispatch);
  }, [dispatch]);

  useEffect(() => {
    void Api.actions.user.getRewardsCount()(dispatch);
  }, [dispatch]);

  return (
    <UserMenuContent
      fullName={fullName}
      freeRounds={freeRounds}
      requiresVerification={requiresVerification}
      verificationStatus={verificationStatus}
      globalJackpotAmount={globalJackpotAmount}
      openDeposit={openDeposit}
      openReferAfriend={openReferAfriend}
      handleVerifyAccountClick={handleVerifyAccountClick}
      openWithdraw={openWithdraw}
      logout={logout}
    />
  );
};

export default makeModal('userMenu', getTransitionType(), {
  className: 'userMenu menu-wrap menu-wrap--user'
})(UserMenuAdapter);
