import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { isLoading } from 'lib/redux-utils';
import Api from 'services/Api';
import Carousel from 'components/Carousel';
import SlotListContainer from 'components/SlotListContainer';
import LiveListContainer from 'components/LiveListContainer';
import LoadingElement from 'components/Loading/LoadingElement';
import CarouselMod from 'modules/Carousel';
import { actionTypes as bingoTypes } from 'modules/Bingo'; // TODO: Remove Bingo related code to optimize bundle size
import RewardsContainer from 'components/RewardsContainer';
import SearchButton from 'components/SearchButton';
import DynamicLobby from 'components/DynamicLobby';
import BingoCard from '../BingoCard'; // TODO: Remove Bingo related code to optimize bundle size
import './lobbyMiddle.css';

const Footer = loadable(() => import('components/Footer'));

const { TAB } = CarouselMod.constants;

const handleSelectBottom = (name, ref) => {
  // No ref, use DOM methods
  const elem = document.querySelector(`.tabs-bottom .tab.${ref}`);
  if (elem) {
    const parent = elem.parentElement;

    if (elem.offsetLeft < parent.scrollLeft) {
      parent.scrollLeft = elem.offsetLeft;
    }
    if (elem.offsetLeft + elem.clientWidth > parent.clientWidth + parent.scrollLeft) {
      parent.scrollLeft = elem.offsetLeft + elem.clientWidth - parent.clientWidth;
    }
  }
};

const transformQuery = (base) => ({
  ...base,
  'max-width-465': {
    maxWidth: 465
  },
  'max-width-360': {
    maxWidth: 360
  }
});

const bingoCards = (cards, loadingBingo) => {
  if (__ENV__.MRQ_BINGO_ENABLED === 'true') {
    return (
      (cards &&
        cards.length > 0 &&
        cards.map((card) => <BingoCard refName={card} key={`${TAB.BINGO}-${card}`} />)) ||
      (loadingBingo && <LoadingElement />)
    );
  } else {
    return null;
  }
};

const View = ({
  top,
  bottom,
  loadingBingo,
  isLoadingSlotCategories,
  isLoadingLiveCategories,
  isActive,
  bottomTabs,
  ...rest
}) => {
  if (top === TAB.SLOTS) {
    return bottomTabs?.[bottom].isDynamicLobby ? (
      <DynamicLobby section={top} category={bottom} isActive={isActive} />
    ) : (
      <SlotListContainer
        top={top}
        category={bottom}
        loadingCategory={isLoadingSlotCategories}
        isActive={isActive}
      />
    );
  } else if (top === TAB.LIVE) {
    return (
      <LiveListContainer
        top={top}
        category={bottom}
        loadingCategory={isLoadingLiveCategories}
        isActive={isActive}
      />
    );
  } else if (top === TAB.REWARDS) {
    return <RewardsContainer />;
  } else if (top === TAB.BINGO) {
    return (
      // TODO: make its own component, the same as we have SlotListContainer, LiveListContainer
      <>
        <div className="bingoList">
          <div className="itemList">{bingoCards(rest.cards[top][bottom], loadingBingo)}</div>
        </div>
        <Footer />
      </>
    );
  } else {
    return null;
  }
};

export const _LobbyMiddle = (props) => {
  const { initialize, isLoadingSlotCategories, isLoadingLiveCategories } = props;
  useEffect(() => {
    initialize();
  }, [initialize]);

  const getBottomPrependTab = useCallback(
    ({ topTabRef }) => {
      const isCasino = topTabRef === TAB.SLOTS;
      const isLive = topTabRef === TAB.LIVE;
      if ((isCasino && !isLoadingSlotCategories) || (isLive && !isLoadingLiveCategories)) {
        return <SearchButton />;
      }
      return null;
    },
    [isLoadingSlotCategories, isLoadingLiveCategories]
  );

  return (
    <Carousel
      portalTop="navBar"
      name="lobbyMiddle"
      getView={<View {...props} />}
      transformQuery={transformQuery}
      onSelectBottom={handleSelectBottom}
      getBottomPrependTab={getBottomPrependTab}
    />
  );
};

const mapStateToProps = (state) => ({
  cards: CarouselMod.selectors.getLobbyCards(state),
  ...(__ENV__.MRQ_BINGO_ENABLED === 'true'
    ? { loadingBingo: isLoading(state, bingoTypes.AT.LIST._) }
    : {}),
  bottomTabs: CarouselMod.selectors.getBottomTabs(state, 'lobbyMiddle'),
  isLoadingSlotCategories: isLoading(state, CarouselMod.actionTypes.AT.LIST_SLOT_CATEGORIES._),
  isLoadingLiveCategories: isLoading(state, CarouselMod.actionTypes.AT.LIST_LIVE_CATEGORIES._)
});

const mapDispatchToProps = (dispatch) => ({
  initialize: () => {
    if (__ENV__.MRQ_BINGO_ENABLED === 'true') {
      Api.actions.bingo.list({ instance: 'next' })(dispatch);
    }
    Api.actions.user.getCurrent()(dispatch);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(_LobbyMiddle);
