import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getAvatarName } from 'lib/avatar';
import './avatar.css';

const DEFAULT_AVATAR = 'greenguy';

const Avatar = ({ image, className, id, title, icon }) => (
  <div className={`avatar ${className}`}>
    <img
      id={id}
      alt=""
      title={title}
      src={
        image.startsWith('http')
          ? image
          : `/assets/images1/avatars/avatar-${getAvatarName(image) || DEFAULT_AVATAR}@2x.png`
      }
    />
    {icon && <div className="avatar__icon">{icon}</div>}
  </div>
);

Avatar.propTypes = {
  image: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string
};

Avatar.defaultProps = {
  image: DEFAULT_AVATAR,
  className: '',
  id: undefined,
  title: 'User avatar'
};

// PureComponent: SIMPLE props only, ALL children must be PURE too!
export default memo(Avatar);
