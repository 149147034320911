import React from 'react';

const LoadingLiveTile = () => (
  <div className="roomCard liveTile loadingTile">
    <div className="loadingTile__thumbnail">
      <div className="main shine" />
    </div>
    <div className="loadingTile__row loadingTile__row--100">
      <div className="main shine" />
    </div>
    <div className="loadingTile__row loadingTile__row--150">
      <div className="main shine" />
    </div>
    <div className="loadingTile__row loadingTile__row--45">
      <div className="main shine" />
    </div>
  </div>
);

export default LoadingLiveTile;
