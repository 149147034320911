// eslint-disable-next-line max-classes-per-file -- Legacy code or implementation limitation
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cc from 'classcat';
import loadable from '@loadable/component';
import update from 'update-immutable';
import { compose } from 'redux';
import Api from 'services/Api';
import reform from 'components/Reform';
import Fieldset from 'components/Fieldset';
import Auth from 'modules/Auth';
import Wallet from 'modules/Wallet';
import Button from 'components/Button';
import FormError from 'components/FormError';
import { isLoading } from 'lib/redux-utils';
import { formatCurrency } from 'lib/formatters';
import { promotionCode as promotionCodeValidation } from 'lib/valFuncs';
import {
  MIN_DEPOSIT,
  MAX_DEPOSIT_CARD,
  depositAmount as depositAmountValidation
} from 'lib/valFunctors';
import AmountPresetPicker from 'components/AmountPresetPicker';
import CvvIcon from 'components/Icons/CvvIcon';
import { createThreeDsecure, braintreeStyle, asyncHandler } from 'lib/braintree';
import BraintreeField from 'components/BraintreeField';
import * as Animation from 'components/Animation';
import withInstanceId from 'components/InstanceId';
import 'css/components/paypal.css'; // TODO: Fix this tech debt? We are not really using global CSS imports?
import './depositCardForm.css';

const Braintree = loadable(() => import('components/ReactBraintreeFields'), {
  fallback: (
    <Animation.Root
      key="LoadingElement"
      className="fieldset securityCode braintree-hosted-field userText"
    >
      <Animation.ShimmerInputPrimary />
    </Animation.Root>
  ),
  resolveComponent: (components) => components.Braintree
});

const radioDefaultValues = [20, 50, 100, 150];

class BaseForm extends Component {
  constructor(props) {
    super(props);
    this.fieldRefs = {};
    this.state = {
      interactiveReady: false,
      cvv: {
        value: '',
        initial: '',
        error: 'Three-digit number on the back of the card',
        valid: null
      },
      error: null
    };
  }

  handleAuthorizationSuccess = () => {
    this.setState({ interactiveReady: true });
    // this.fieldRefs.cvv?.focus(); // no need to steal the focus
    // TODO: Load 3ds early
    // threeDSecure has been loaded as Braintree was loaded in the same module
    //    await threeDSecure;
    //    this.threeDS = await threeDSecure.create({
    //      authorization: this.props.token,
    //      version: '2'
    //    });
  };

  handleAmountPresetChange = (value) => {
    const { handleChange } = this.props;
    handleChange({}, { name: 'depositAmount', value });
  };

  handleValidityChange = ({ target, valid }) => {
    this.setState((state) => update(state, { [target]: { valid: { $set: valid } } }));
  };

  setBraintreeRef = (ref) => {
    // eslint-disable-next-line react/no-unused-class-component-methods -- No idea whether we need this for the SDK. Seems unused.
    this.braintree = ref;
  };

  setCvvRef = (ref) => {
    this.fieldRefs.cvv = ref;
  };

  handleError = (error) => {
    this.setState({ error });
  };

  render() {
    const {
      depositAmount,
      promotionCode,
      loading,
      disabled,
      formValid,
      handleChange,
      handleFocus,
      handleBlur,
      errorMessage,
      clientToken,
      radioValues,
      loadingToken,
      loadDataCollector,
      braintreeIsProcessing,
      braintreeErrorMessage,
      clientTokenError,
      minAmount,
      maxAmount,
      fundsProtection,
      disableDepositButton,
      onSubmit
    } = this.props;
    const { error, interactiveReady, cvv } = this.state;
    const buttonClass = cc([{ 'button--loading': loading }]);
    const isDepositButtonDisabled =
      disabled ||
      !formValid ||
      !depositAmount.valid ||
      !this.state.cvv.valid ||
      disableDepositButton;

    return (
      <form
        id="depositCardForm"
        name="depositBraintreeCard"
        onSubmit={onSubmit}
        className={cc(['depositBraintreeCardForm', disabled ? 'form form--disabled' : 'form'])}
      >
        <div>
          {/* designers prefers to not have a MagicMove transition here so not using it but simple div */}
          {/* If loadingToken or not clientToken (most likely means there was an error loading it)
        show Animation else show Braintree */}
          {loadingToken || !clientToken ? (
            /* 'braintree-hosted-field' gives us the height
               while 'fieldset securityCode' gives us the width
               and 'userText' comes with border-radius */
            <Animation.Root
              className="fieldset securityCode braintree-hosted-field userText"
              isPaused={!loadingToken && !clientToken}
            >
              <Animation.ShimmerInputPrimary />
            </Animation.Root>
          ) : (
            <Braintree
              ref={this.setBraintreeRef}
              authorization={clientToken}
              onAuthorizationSuccess={this.handleAuthorizationSuccess}
              onError={this.handleError}
              getTokenRef={this.props.setTokenizeRef}
              onDataCollectorInstanceReady={loadDataCollector}
              isLoading={loading}
              styles={braintreeStyle}
            >
              <BraintreeField
                name="securityCode"
                id="cvv"
                labelText="CVV"
                inputType="cvv"
                field={cvv}
                ref={this.setCvvRef}
                isLoading={loading && interactiveReady}
                onValidityChange={this.handleValidityChange}
                onInputSubmitRequest={onSubmit}
                icon={<CvvIcon />}
                isOutsideIcon
                iconWidth={42}
                editable
                showServerError
              />
            </Braintree>
          )}
        </div>
        <div className={cc(['field field--form', disabled && 'noEdit'])}>
          <span className="field__title">Deposit amount</span>
          <div className="field__content">
            <div className="fieldset fieldset--fullwidth depositPreset">
              <AmountPresetPicker
                values={radioValues || radioDefaultValues}
                onChange={this.handleAmountPresetChange}
                value={+depositAmount.value}
              />
            </div>
            <Fieldset
              field={depositAmount}
              editable={!disabled}
              inputType="number"
              min={(minAmount || MIN_DEPOSIT).toString()}
              max={(maxAmount || MAX_DEPOSIT_CARD).toString()}
              step="1"
              name="depositAmount"
              className="fieldset--pound"
              labelText="Other"
              onChange={handleChange}
              onFocus={handleFocus}
            />
          </div>
        </div>
        <Fieldset
          field={promotionCode}
          inputType="text"
          name="promotionCode"
          labelText="Promo code"
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          editable={!disabled}
        />
        {fundsProtection}
        <div className="fieldset fieldset--centerflex">
          <Button
            className={buttonClass}
            disabled={isDepositButtonDisabled}
            loading={loading || braintreeIsProcessing}
            type="submit"
            buttonText={cc(['Deposit', depositAmount.value && formatCurrency(depositAmount.value)])}
          />
        </div>
        <FormError
          errorMessage={
            errorMessage ||
            braintreeErrorMessage ||
            (error && error.toString()) ||
            (!clientToken && clientTokenError) ||
            undefined
          }
        />
      </form>
    );
  }
}

export const Form = reform()(BaseForm);

// eslint-disable-next-line react/no-multi-comp
export class _DepositBraintreeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientTokenError: null,
      errorMessage: null
    };
    // Braintree data collector
    // We don't use the same data collector as PayPal as it's provided for free by the Hosted Fields
    this.loadDataCollector = asyncHandler();
  }

  componentDidMount() {
    this.mounted = true;
    Braintree.preload();
    this.props.requestAuthorization();
  }

  componentWillUnmount() {
    this.props.clearAuthorization();
    this.mounted = false;
  }

  getTokenizedPaymentMethod = async () => {
    try {
      const tokenizedPaymentMethod = await this.tokenize({ fieldsToTokenize: ['cvv'] });
      if (this.mounted) this.setState({ errorMessage: null });
      return tokenizedPaymentMethod;
    } catch (error) {
      if (this.mounted) this.setState({ errorMessage: error.message });
      return null;
    }
  };

  setTokenizeRef = (ref) => {
    this.tokenize = ref;
  };

  submit = async (data) => {
    const { loading, email, firstName, lastName, address, submitBraintree, getNonce, clientToken } =
      this.props;

    try {
      this.setState({ clientTokenError: null, braintreeIsProcessing: true });

      if (!loading) {
        const [tokenizedPaymentMethod, threeDSecure, dataCollector, { data: nonce }] =
          await Promise.all([
            this.getTokenizedPaymentMethod(),
            createThreeDsecure(clientToken),
            this.loadDataCollector,
            getNonce()
          ]);

        // If promise didn't reject, but one of these is empty
        if (!tokenizedPaymentMethod || !nonce || !dataCollector) {
          const errorMessage = 'An error occurred. Please try again.';
          if (this.mounted) {
            this.setState({
              braintreeIsProcessing: false,
              errorMessage
            });
          }
          return errorMessage;
        }
        const {
          nonce: cvvNonce,
          details: { bin }
        } = tokenizedPaymentMethod;

        const { nonce: cardVerificationNonce, threeDSecureInfo } = await threeDSecure.verifyCard({
          onLookupComplete: (lookupResponse, next) => {
            next();
          },
          nonce,
          bin,
          amount: data.depositAmount.toString(),
          email,
          billingAddress: {
            givenName: firstName,
            surname: lastName,
            streetAddress: address.line1,
            extendedAddress: address.line2,
            locality: address.city,
            postalCode: address.postalCode,
            countryCodeAlpha2: 'GB'
          }
        });

        if (
          __ENV__.MRQ_BRAINTREE_LIABILITY_SHIFT === 'true' ||
          threeDSecureInfo?.liabilityShifted
        ) {
          // 3ds passed
          const result = submitBraintree({
            ...data,
            cvvNonce,
            deviceData: dataCollector.deviceData,
            threeDSecureId: threeDSecureInfo.threeDSecureAuthenticationId,
            cardVerificationNonce
          });
          if (this.mounted) this.setState({ errorMessage: null, braintreeIsProcessing: false });
          return result;
        } else if (this.mounted) {
          this.setState({
            errorMessage: 'Bank verification failed.',
            braintreeIsProcessing: false
          });
        }
      }
    } catch (e) {
      if (this.mounted) {
        this.setState({
          braintreeIsProcessing: false,
          errorMessage:
            e.message ||
            e.msg ||
            'There was a problem contacting Braintree. Please try another payment option.'
        });
      }
    }
  };

  render() {
    const { clientTokenError, errorMessage, braintreeIsProcessing } = this.state;
    const { clientToken, defaultAmount, minAmount, maxAmount, promoCode } = this.props;
    const fields = {
      depositAmount: {
        initial: defaultAmount || 50,
        required: true,
        error: `Minimum amount ${formatCurrency(10)}`,
        onChange: depositAmountValidation(minAmount, maxAmount)
      },
      promotionCode: {
        initial: promoCode || '',
        required: false,
        onChange: promotionCodeValidation,
        error: "This doesn't look like a valid code"
      }
    };

    return (
      <Form
        {...this.props}
        fields={fields}
        clientToken={clientToken}
        clientTokenError={clientTokenError}
        braintreeIsProcessing={braintreeIsProcessing}
        braintreeErrorMessage={errorMessage}
        submit={this.submit}
        loadDataCollector={this.loadDataCollector.callback}
        setTokenizeRef={this.setTokenizeRef}
      />
    );
  }
}

const mapStateToProps = (state, { provider, instanceId }) => ({
  email: state.User.email,
  address: state.User.homeAddress,
  firstName: state.User.firstName,
  lastName: state.User.lastName,
  deviceData: state.Wallet.deviceData,
  radioValues: provider && Wallet.selectors.getPaymentPresetPresets(state, provider),
  defaultAmount: provider && Wallet.selectors.getPaymentPresetPresets(state, provider)?.[0],
  minAmount: provider && Wallet.selectors.getPaymentPresetMinAmount(state, provider),
  maxAmount: provider && Wallet.selectors.getPaymentPresetMaxAmount(state, provider),
  clientToken: state.Wallet.token[instanceId],
  loadingToken: isLoading(state, [Wallet.actionTypes.AT.GET_TOKEN._]),
  loading: isLoading(state, [
    Auth.AT.FINGERPRINT._,
    Wallet.actionTypes.AT.DEPOSIT_CARD_BRAINTREE._,
    Wallet.actionTypes.AT.GET_NONCE._,
    Wallet.actionTypes.AT.GET_TOKEN._
  ])
});

const mapDispatchToProps = (
  dispatch,
  { onDone, paymentMethodRef, instanceId, agreeDepositTerms }
) => ({
  requestAuthorization: () => Api.actions.wallet.getToken({ instanceId })(dispatch),
  clearAuthorization: () => dispatch(Wallet.actions.deleteToken(instanceId)),
  getNonce: () => Api.actions.wallet.getNonce({ ref: paymentMethodRef })(dispatch),
  submitBraintree: async ({
    depositAmount,
    promotionCode,
    deviceData,
    cvvNonce,
    threeDSecureId,
    cardVerificationNonce
  }) => {
    const { value: deviceInfo } = await dispatch(Auth.actions.getDeviceFingerprint());
    const result = await Api.actions.wallet.depositCardBraintree(null, {
      paymentMethodRef,
      amount: depositAmount,
      promotionCode,
      deviceData,
      cvvNonce,
      threeDSecureId,
      cardVerificationNonce,
      deviceInfo,
      agreeDepositTerms
    })(dispatch, true);
    onDone(depositAmount);
    return result;
  }
  // setValidity: (valid) => dispatch(Wallet.actions.validateFormCard(valid))
});

export default compose(
  withInstanceId(),
  connect(mapStateToProps, mapDispatchToProps)
)(_DepositBraintreeForm);
