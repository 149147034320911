import styleguideStyleClassic from 'css/preskin/styleguide.css?lazy';
import globalStyleClassic from 'css/preskin/global.css?lazy';
import fieldStyleClassic from 'css/preskin/components/field.css?lazy';
import fieldSetStyleClassic from 'css/preskin/components/fieldset.css?lazy';
import styleguideStyle from 'css/styleguide.css?lazy';
import globalStyle from 'css/global.css?lazy';
import fieldStyle from 'css/components/field.css?lazy';
import fieldSetStyle from 'css/components/fieldset.css?lazy';
import useGoogleOptimize from '@react-hook/google-optimize';
import React, { memo, useEffect, useState } from 'react';
import { themes } from 'lib/constants';
import FullscreenLoading from 'components/Loading/Fullscreen';
import { ACTIVE_EXPERIMENTS } from 'modules/Analytics';
import './typographyFonts.css';

const OPTIMIZE_TIMEOUT = 250;

const Theme = ({ pathname, children }) => {
  const isClassicVariant = true;

  const [loadedTheme, setLoadedTheme] = useState(null);
  const isSignup = pathname.startsWith('/signup');

  // TODO: Try in ClassicSignup
  // TODO: Try @import
  useEffect(() => {
    if (!isSignup || isClassicVariant === false) {
      // Immediately set theme to reskin if we're not in /signup, or if Google has replied with "reskin"
      styleguideStyle.use();
      globalStyle.use();
      fieldStyle.use();
      fieldSetStyle.use();
      if (loadedTheme === themes.CLASSIC) {
        styleguideStyleClassic.unuse();
        globalStyleClassic.unuse();
        fieldStyleClassic.unuse();
        fieldSetStyleClassic.unuse();
      }
      setLoadedTheme(themes.RESKIN);
    } else if (isClassicVariant === true) {
      styleguideStyleClassic.use();
      globalStyleClassic.use();
      fieldStyleClassic.use();
      fieldSetStyleClassic.use();
      if (loadedTheme === themes.RESKIN) {
        styleguideStyle.unuse();
        globalStyle.unuse();
        fieldStyle.unuse();
        fieldSetStyle.unuse();
      }
      setLoadedTheme(themes.CLASSIC);
    }

    return () => {
      if (loadedTheme === themes.CLASSIC) {
        styleguideStyleClassic.unuse();
        globalStyleClassic.unuse();
        fieldStyleClassic.unuse();
        fieldSetStyleClassic.unuse();
      } else if (loadedTheme === themes.RESKIN) {
        styleguideStyle.unuse();
        globalStyle.unuse();
        fieldStyle.unuse();
        fieldSetStyle.unuse();
      }
    };
  }, [isClassicVariant, isSignup, loadedTheme]);

  // Essentially an anti-flicker mechanism. Use <FullscreenLoading /> if timeout could be too long
  if (!isSignup || isClassicVariant !== null) {
    return children;
  } else if (OPTIMIZE_TIMEOUT > 250) {
    return <FullscreenLoading />;
  } else {
    return null;
  }
};

export default memo(Theme);
