import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import loadable from '@loadable/component';
import Live from 'modules/Live';
import LoadingLiveGamesList from './LoadingLiveGamesList';
import LiveGamesList from './LiveGamesList';

const LAZY_MS = 400;
const INITIAL_SIZE = 24;
const PAGE_SIZE = 10;

const Footer = loadable(() => import('components/Footer'));

const isNearBottom = (scrollTop, scrollHeight, clientHeight) =>
  scrollTop >= scrollHeight - clientHeight * 1.33;

export class _LiveListContainer extends Component {
  componentDidMount() {
    this.scrollContainer = this.domList.parentNode;
    const { isActive, guard, getLive, category, loading, isolatedRefCount } = this.props;

    if (guard) return;

    if (isActive && !loading) {
      getLive(category, isolatedRefCount).then(this.handleScroll);
    }

    this.attachListeners();
  }

  componentDidUpdate(prevProps) {
    const {
      isActive: wasActive,
      isolatedRefCount: previousIsolatedRefCount,
      guard: previousGuard
    } = prevProps;
    const { isActive, guard, getLive, category, isolatedRefCount, offset, loading } = this.props;

    if (!previousGuard && guard) {
      this.cleanup();
      return;
    }

    if (guard) return;

    if (!loading && !wasActive && isActive) {
      getLive(category, offset).then(this.handleScroll);
    }

    if (!previousIsolatedRefCount && isolatedRefCount) {
      this.attachListeners();
    }

    if (previousIsolatedRefCount !== isolatedRefCount && isActive) {
      this.recalculateSizeSync();
    } else if (!wasActive && isActive) {
      this.recalculateSize();
    }

    if (
      !this.pause &&
      isActive &&
      !loading &&
      previousIsolatedRefCount &&
      previousIsolatedRefCount !== isolatedRefCount &&
      isNearBottom(this.scrollContainer.scrollTop, this.cachedScrollHeight, this.cachedClientHeight)
    ) {
      this.pause = true;
      getLive(category, offset).then(this.unpause);
    }
  }

  componentWillUnmount() {
    this.cleanup();
  }

  handleScroll = (sync = false) => {
    if (!this.props.isActive || this.props.loading || this.props.guard) return;
    if (sync) {
      this.recalculateSizeSync();
    } else {
      this.recalculateSize();
    }
    // e.target == this.scrollContainer
    // scrollHeight - scrollTop = clientHeight to get to the very bottom. We'll need a buffer zone, eg 33% of the client height
    if (
      isNearBottom(
        this.scrollContainer.scrollTop,
        this.cachedScrollHeight,
        this.cachedClientHeight
      ) &&
      !this.pause
    ) {
      this.pause = true;
      const { category, offset, getLive } = this.props;
      getLive(category, offset).then(this.unpause);
    }
  };

  recalculateSizeSync = () => {
    if (!this.props.isActive) return;
    this.cachedScrollHeight = this.scrollContainer.scrollHeight;
    this.cachedClientHeight = this.scrollContainer.clientHeight;
  };

  handleScrollSync = () => this.handleScroll(true);

  // eslint-disable-next-line react/sort-comp
  recalculateSize = debounce(this.recalculateSizeSync, LAZY_MS);

  setItemListRef = (element) => {
    this.itemList = element;
  };

  setDomListRef = (element) => {
    this.domList = element;
  };

  unpause = () => {
    this.pause = false;
    this.handleScroll();
  };

  attachListeners = () => {
    if (this.hasListeners) return;

    this.hasListeners = true;
    this.scrollContainer.addEventListener('scroll', this.handleScroll, { passive: true });
    window.addEventListener('resize', this.handleScroll, { passive: true });
    window.addEventListener('orientationchange', this.handleScrollSync, { passive: true });
    this.recalculateSize();
  };

  cleanup = () => {
    this.scrollContainer.removeEventListener('scroll', this.handleScroll, { passive: true });
    window.removeEventListener('resize', this.handleScroll, { passive: true });
    window.removeEventListener('orientationchange', this.handleScrollSync, { passive: true });
    this.recalculateSize.cancel();
  };

  render() {
    const { loading, liveGameRefs, guard, category } = this.props;
    const loadingCardCount = liveGameRefs?.length ? PAGE_SIZE : INITIAL_SIZE;
    return (
      <>
        <div className="slotList" ref={this.setDomListRef}>
          <div className="itemList" ref={this.setItemListRef}>
            {liveGameRefs && liveGameRefs.length > 0 && (
              <LiveGamesList
                refs={liveGameRefs}
                path="/secure/games"
                category={category}
                source="Lobby"
              />
            )}
            {loading && <LoadingLiveGamesList count={loadingCardCount} />}
          </div>
        </div>
        {guard && <Footer />}
      </>
    );
  }
}

_LiveListContainer.propTypes = {
  isActive: PropTypes.bool.isRequired,
  guard: PropTypes.bool.isRequired,
  category: PropTypes.string.isRequired,
  getLive: PropTypes.func.isRequired,
  isolatedRefCount: PropTypes.number,
  offset: PropTypes.number,
  loading: PropTypes.bool.isRequired
};

_LiveListContainer.defaultProps = {
  isolatedRefCount: 0,
  offset: 0
};

const mapStateToProps = (state, { category, loadingCategory }) => ({
  guard: Live.selectors.getCategoryFullyLoaded(state, category),
  isolatedRefCount: Live.selectors.getIsolatedRefCount(state, category),
  offset: Live.selectors.getCategoryOffset(state, category),
  liveGames: Live.selectors.getRooms(state),
  liveGameRefs: Live.selectors.getCategoryRefs(state, category),
  loading: loadingCategory || Live.selectors.getLoadingByCategory(state, category)
});

const mapDispatchToProps = (dispatch) => ({
  getLive: (categ, offset) => {
    const args = {
      categ,
      offset,
      limit: !offset ? INITIAL_SIZE : PAGE_SIZE,
      sort: ['position', 'ASC']
    };

    return Live.actions.getLive(args)(dispatch);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(_LiveListContainer);
