import React, { type MouseEvent } from 'react';
import { MagicMove } from 'components/Transitions';
import LoadingElement from 'components/Loading/LoadingElement';
import SetLimits from 'components/AddPayment/components/SetLimits';
import { abFirstDepositRedesign } from 'lib/experiments';
import PaymentMethodsChooser from '../PaymentMethodsChooser';
import PaymentMethodsProvidersList from './components/PaymentMethodsProvidersList';

interface FirstDepositSetupProps {
  hasLoaded: boolean;
  limitsDone: boolean;
  availablePaymentMethodProviders: string[];
  handleClick: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDoneDepositLimit: () => void;
}

const FirstDepositSetup = ({
  hasLoaded,
  limitsDone,
  onDoneDepositLimit,
  availablePaymentMethodProviders,
  handleClick
}: FirstDepositSetupProps) => (
  <MagicMove childKey={hasLoaded ? 'LoadingElement' : 'firstDepositSetup'}>
    {!hasLoaded ? (
      <LoadingElement />
    ) : (
      <>
        {(!limitsDone || abFirstDepositRedesign.scenarios.inNewDesign) && (
          <SetLimits key="setLimits" onDoneDepositLimit={onDoneDepositLimit} />
        )}
        {abFirstDepositRedesign.scenarios.inNewDesign ? (
          <PaymentMethodsProvidersList
            key="chooser"
            onClickHandler={handleClick}
            // In redesign, we don't prevent them from proceeding if they haven't set limits or accepted funds protection
            // previewMode={!inNewDesign && (!limitsDone || !(protectionDone || skipFundsProtection))}
          />
        ) : (
          <PaymentMethodsChooser
            key="chooser"
            onClickHandler={handleClick}
            previewMode={!limitsDone}
            availableTypes={availablePaymentMethodProviders}
          />
        )}
      </>
    )}
  </MagicMove>
);

export default FirstDepositSetup;
