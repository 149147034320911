import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Live from 'modules/Live';
import Analytics from 'modules/Analytics';
import cfImage from 'lib/cfImage';
import User from 'modules/User';
import 'components/SlotCard/roomCard.css';
import 'components/SlotCard/slotRoomCard.css';
import EmbeddedTileInfo from './src/EmbeddedTileInfo';
import BottomTileInfo from './src/BottomTileInfo';
import './liveTile.css';

export const _LiveTile = ({
  name,
  lobbyImageUrl,
  refName,
  tileInfo,
  blur,
  trackLaunch,
  provider,
  platform,
  type,
  category,
  source
}) => {
  const { minBet, playerCount, displayProvider } = tileInfo || {};
  const imageURL = cfImage(lobbyImageUrl, {
    ignoreGif: !blur,
    blur: blur && 25,
    brightness: blur && 0.8
  });

  const trackGameLaunch = useCallback(() => {
    trackLaunch({
      refName,
      name,
      provider,
      section: type,
      platform,
      source,
      category
    });
  }, [trackLaunch, name, platform, provider, refName, type, category, source]);

  return (
    <Link className="roomCard liveTile" to={`/secure/games/${refName}`} onClick={trackGameLaunch}>
      <div
        style={{
          background: `url('${imageURL}') center center / cover no-repeat`
        }}
        className="tileBackground"
      >
        <EmbeddedTileInfo tileInfo={tileInfo} />
      </div>
      <BottomTileInfo
        title={name}
        minBet={minBet}
        provider={displayProvider}
        playerCount={playerCount}
      />
    </Link>
  );
};

_LiveTile.propTypes = {
  name: PropTypes.string,
  lobbyImageUrl: PropTypes.string,
  tileInfo: PropTypes.object,
  type: PropTypes.oneOf(['slot', 'live']),
  category: PropTypes.string,
  source: PropTypes.string,
  provider: PropTypes.string,
  platform: PropTypes.string,
  blur: PropTypes.bool,
  refName: PropTypes.string
};

_LiveTile.defaultProps = {
  name: '',
  lobbyImageUrl: '',
  tileInfo: null,
  blur: false,
  type: 'live',
  category: '',
  source: 'Lobby',
  provider: null,
  platform: null,
  refName: null
};

const mapStateToProps = (state, { refName }) => {
  const { name, lobbyImageUrl, tileInfo, blur, provider, platform } =
    Live.selectors.getRoom(state, refName) || {};
  const kycStatus = User.selectors.getKycStatus(state);
  const shouldBlur = blur && kycStatus !== User.constants.kyc.PASS;

  return {
    name,
    lobbyImageUrl,
    tileInfo,
    provider,
    platform,
    blur: shouldBlur
  };
};

const mapDispatchToProps = (dispatch) => ({
  trackLaunch: (payload) => dispatch(Analytics.actions.gameLaunched(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(_LiveTile);
export { EmbeddedTileInfo };
