/* eslint-disable react/prop-types -- Legacy code, might refactor at some point */
// eslint-disable-next-line max-classes-per-file -- Legacy code, might refactor at some points
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cc from 'classcat';
import { compose } from 'redux';
import Api from 'services/Api';
import reform from 'components/Reform';
import Fieldset from 'components/Fieldset';
import Wallet from 'modules/Wallet';
import FormError from 'components/FormError';
import LoadingElement from 'components/Loading/LoadingElement';
import AmountPresetPicker from 'components/AmountPresetPicker';
import { formatCurrency } from 'lib/formatters';
import Paypal from 'components/Paypal';
import { isLoading } from 'lib/redux-utils';
import { promotionCode as promotionCodeValidation } from 'lib/valFuncs';
import {
  depositAmountPaypal as depositAmountValidation,
  MAX_DEPOSIT_PAYPAL,
  MIN_DEPOSIT
} from 'lib/valFunctors';
import 'css/components/paypal.css'; // TODO: Fix this tech debt? We are not really using global CSS imports?
import './depositCardForm.css';

const radioDefaultValues = [20, 50, 100, 150];

const enhance = compose(reform());

class BaseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropinLoaded: false
    };
  }

  componentDidUpdate({ nonce: prevNonce }) {
    if (!prevNonce && this.props.nonce) {
      // This is how you trigger the synthetic submit event in React /react/issues/6796
      this.form.dispatchEvent(new Event('submit'));
    }
  }

  handleAmountPresetChange = (value) => {
    const { handleChange } = this.props;
    handleChange({}, { name: 'depositAmount', value });
  };

  handleInstance = () => {
    this.setState({ dropinLoaded: true });
  };

  formRef = (element) => {
    this.form = element;
  };

  render() {
    const {
      fundsProtection,
      disableDepositButton,
      depositAmount,
      promotionCode,
      radioValues,
      loading,
      disabled,
      formValid,
      handleChange,
      handleFocus,
      handleBlur,
      handlePaymentMethod,
      clientToken,
      minAmount,
      maxAmount,
      clientTokenError,
      dropinKey,
      errorMessage,
      onSubmit
    } = this.props;
    const { dropinLoaded } = this.state;
    const cannotDeposit = disabled || !formValid || !depositAmount.valid;
    return (
      <form
        id="depositCardForm"
        name="depositPaypalNew"
        onSubmit={onSubmit}
        className={disabled ? 'form form--disabled' : 'form'}
        ref={this.formRef}
      >
        <div className={cc(['field field--form', disabled && 'noEdit'])}>
          <span className="field__title">Deposit amount</span>
          <div className="field__content">
            <div className="fieldset fieldset--fullwidth depositPreset">
              <AmountPresetPicker
                values={radioValues || radioDefaultValues}
                onChange={this.handleAmountPresetChange}
                value={+depositAmount.value}
              />
            </div>
            <Fieldset
              field={depositAmount}
              editable={!disabled}
              inputType="number"
              min={(minAmount || MIN_DEPOSIT).toString()}
              max={(maxAmount || MAX_DEPOSIT_PAYPAL).toString()}
              step="1"
              name="depositAmount"
              className="fieldset--pound"
              labelText="Other"
              onChange={handleChange}
              onFocus={handleFocus}
            />
          </div>
        </div>
        <Fieldset
          field={promotionCode}
          inputType="text"
          name="promotionCode"
          labelText="Promo code"
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          editable={!disabled}
        />
        {fundsProtection}
        {clientToken ? (
          <div
            id="dropin-container"
            className={cc([
              'field',
              'dropin-container',
              (!dropinLoaded || loading) && 'dropin-container--hidden',
              dropinLoaded && disableDepositButton && 'dropin-container--forbidFundsToC',
              cannotDeposit && 'dropin-container--forbid',
              cannotDeposit && dropinLoaded && 'dropin-container--prompt'
            ])}
          >
            <Paypal
              key={dropinKey}
              commitToPay
              onPaymentMethod={handlePaymentMethod}
              onInstance={this.handleInstance}
              clientToken={clientToken}
            />
          </div>
        ) : null}
        {loading || !dropinLoaded || (!clientToken && !clientTokenError) ? (
          <LoadingElement display="medium" />
        ) : null}
        <FormError errorMessage={errorMessage || (!clientToken && clientTokenError) || undefined} />
      </form>
    );
  }
}

export const Form = enhance(BaseForm);

// eslint-disable-next-line react/no-multi-comp -- Legacy code, might refactor in the future
export class _DepositPaypalFormNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientTokenError: null,
      nonce: null,
      /** Pending data to deposit (amount, promo code etc) */
      preparedDeposit: null,
      /** Control when the dropin gets remounted */
      deferredDropinKey: props.dropinKey,
      deviceData: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { clientToken, requestAuthorization } = this.props;
    // Get a client token for authorization from your server
    if (!clientToken) {
      requestAuthorization()
        .then(() => this.mounted && this.setState({ clientTokenError: null }))
        .catch(
          () =>
            this.mounted &&
            this.setState({
              clientTokenError:
                'There was a problem contacting PayPal. Please try another payment option.'
            })
        );
    }
  }

  componentDidUpdate(
    { paypalPaymentMethodRef: prevPaypalPaymentMethodRef, dropinKey: prevDropinKey },
    { preparedDeposit: prevPreparedDeposit }
  ) {
    const { paypalPaymentMethodRef, dropinKey, deposit, requestAuthorization } = this.props;
    const { preparedDeposit, deviceData } = this.state;
    if (
      !(prevPaypalPaymentMethodRef && prevPreparedDeposit) &&
      paypalPaymentMethodRef &&
      preparedDeposit
    ) {
      deposit(paypalPaymentMethodRef, { ...preparedDeposit, deviceData });
    }

    if (prevDropinKey !== dropinKey) {
      // There was a backend websocket error adding Paypal, restart the process
      requestAuthorization()
        .then(
          () =>
            this.mounted &&
            this.setState({
              clientTokenError: null,
              nonce: null,
              preparedDeposit: null,
              deviceData: null,
              deferredDropinKey: dropinKey
            })
        )
        .catch(
          () =>
            this.mounted &&
            this.setState({
              clientTokenError:
                'There was a problem contacting PayPal. Please try another payment option.',
              nonce: null,
              preparedDeposit: null,
              deviceData: null,
              deferredDropinKey: dropinKey
            })
        );
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handlePaymentMethod = ({ nonce, deviceData }) => {
    if (this.mounted) this.setState({ nonce, deviceData });
  };

  submit = async (data) => {
    try {
      await this.props.create(this.state);
      if (this.mounted) {
        this.setState({ preparedDeposit: data });
      }
    } catch (e) {
      if (this.mounted) {
        this.setState({ preparedDeposit: null });
      }
      throw e;
    }
  };

  render() {
    const {
      fundsProtection,
      disableDepositButton,
      loading,
      clientToken,
      minAmount,
      maxAmount,
      defaultAmount
    } = this.props;
    const { nonce, deviceData, clientTokenError, deferredDropinKey } = this.state;

    const fields = {
      depositAmount: {
        initial: defaultAmount || 50,
        required: true,
        error: `Minimum amount ${formatCurrency(10)}`,
        onChange: depositAmountValidation(minAmount, maxAmount)
      },
      promotionCode: {
        required: false,
        onChange: promotionCodeValidation,
        error: "This doesn't look like a valid code"
      }
    };

    return (
      <Form
        fields={fields}
        loading={loading}
        nonce={nonce}
        deviceData={deviceData}
        clientToken={clientToken}
        clientTokenError={clientTokenError}
        submit={this.submit}
        handlePaymentMethod={this.handlePaymentMethod}
        handleInstance={this.handleInstance}
        dropinKey={deferredDropinKey}
        fundsProtection={fundsProtection}
        disableDepositButton={disableDepositButton}
      />
    );
  }
}

const mapStateToProps = (state, { provider }) => ({
  radioValues: provider && Wallet.selectors.getPaymentPresetPresets(state, provider),
  defaultAmount: provider && Wallet.selectors.getPaymentPresetPresets(state, provider)?.[0],
  minAmount: provider && Wallet.selectors.getPaymentPresetMinAmount(state, provider),
  maxAmount: provider && Wallet.selectors.getPaymentPresetMaxAmount(state, provider),
  dropinKey: Wallet.selectors.getDropinKey(state),
  clientToken: state.Wallet.paypalToken,
  paypalPaymentMethodRef: Wallet.selectors.getPaypalPaymentMethodRef(state),
  loading: isLoading(state, [
    Wallet.actionTypes.AT.DEPOSIT_PAYPAL._,
    Wallet.actionTypes.AT.CREATE_PAYPAL._,
    Wallet.actionTypes.AT.PAYPAL_TOKEN._
  ])
});

const mapDispatchToProps = (dispatch, { onDone }) => ({
  requestAuthorization: () => Api.actions.wallet.paypalToken()(dispatch),
  deposit: async (
    paymentMethodRef,
    { depositAmount, promotionCode, deviceData, agreeDepositTerms }
  ) => {
    // eslint-disable-next-line no-useless-catch -- Not sure why, but adding comment to bypass eslint. Probably unnecessary try/catch
    try {
      const amount = depositAmount;
      const result = await Api.actions.wallet.depositPaypal(null, {
        agreeDepositTerms,
        paymentMethodRef,
        deviceData,
        amount,
        promotionCode
      })(dispatch, true);
      if (onDone) {
        onDone(amount, true);
      }
      return result;
    } catch (e) {
      throw e;
    }
  },
  create: async ({ nonce }) => {
    // eslint-disable-next-line no-useless-catch -- Not sure why, but adding comment to bypass eslint. Probably unnecessary try/catch
    try {
      return await Api.actions.wallet.createPaypal(null, {
        paymentMethodType: 'PAYPAL',
        details: { nonce }
      })(dispatch, true);
    } catch (e) {
      throw e;
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(_DepositPaypalFormNew);
