export const NAME = 'User';

export const kyc = {
  PENDING: 'PENDING',
  PASS: 'PASS',
  REFER: 'REFER',
  FAIL: 'FAIL',
  NONE: 'NONE'
} as const;

export const kycProviders = {
  YOTI: 'YOTI',
  HOOYU: 'HOOYU',
  MANUAL: 'MANUAL'
};

export const kycMessages = {
  NONE: `Before you can play at MrQ, you need to add a payment method so we can verify your age and identity – don’t worry, you don’t need to make a deposit`,
  NONE_NOTIFICATION: `Before you can play at MrQ, you need to {{add a payment method}openAddPayment} so we can verify your age and identity – don’t worry, you don’t need to make a deposit!`,
  PASS: `You're all set to enjoy MrQ! 🎉`,
  PASS_NOTIFICATION: `We've successfully verified your account. Let's start playing! 🥳`,
  REFER: `We haven't been able to verify your account electronically, so we need a little more information before you can get stuck in.

  
  Get up and running in just a few moments by uploading your documents.`,
  REFER_NOTIFICATION: `We’ve been unable to verify your account electronically, so we need a little more info before you can get stuck in.
   You can {{upload your documents within minutes ⏱️!}openVerifyYourAccount}`,
  FAIL: `It looks like we're going to have to do this the old-fashioned way.  

Use the button below to send a proof of ID and a proof of address to our Verification Team via email.  

They will review your documents and get back to you *within 48 hours*.`,
  FAIL_NOTIFICATION: `Aww man! We couldn’t verify your identity automatically. Please {{send us your documents via email}openVerifyDocuments} to help us get your account up and running.`,
  PENDING: `Once we verify your information, you’ll be able to enjoy all our games. Hang in there, as this could take a few minutes.`
};

export const kycTitles = {
  PENDING: 'Almost there!',
  PASS: 'Verification complete',
  REFER: 'Before you play',
  FAIL: 'Yikes!',
  NONE: 'Before you play'
};

export const kycImages = {
  PENDING: '/assets/images1/kyc/verification-pending.gif',
  REFER: '/assets/images1/kyc/verification.svg',
  FAIL: '/assets/images1/kyc/verification-failed.svg'
};

export enum RewardStatus {
  Unseen = 'Unseen',
  Seen = 'Seen',
  Locked = 'Locked'
}

export enum RewardType {
  FREE_SPINS = 'FREE_SPINS',
  MONETARY = 'MONETARY'
}

/** Flags for User Journey, used in creating the Hooyu session */
export enum VerificationType {
  /** Know Your Customer Flag */
  KYC = 'kyc',
  /**  Standard Due Diligence Flag */
  SDD = 'sdd',
  /** Enhanced Due Diligence Flag */
  EDD = 'edd'
}

export enum VerificationStatus {
  REQUESTED = 'REQUESTED',
  PENDING = 'PENDING',
  PASS = 'PASS'
}

export enum VerificationProvider {
  HOOYU = 'HOOYU',
  EMAIL = 'EMAIL'
}

export enum VerificationDocumentType {
  POI = 'POI', // Proof of Identity
  ADDITIONAL_POI = 'ADDITIONAL_POI', // Additional Proof of Identity
  POA = 'POA', // Proof of Address
  POO_CARD = 'POO_CARD', // Proof of Ownership of Card
  POO_MOBILE = 'POO_MOBILE', // Proof of Ownership of Mobile
  ID_NEXT_TO_FACE = 'ID_NEXT_TO_FACE', // ID next to face
  ID_NEXT_TO_FACE_AND_DATE = 'ID_NEXT_TO_FACE_AND_DATE', // ID next to face and date written on card
  THIRD_PARTY_ID = 'THIRD_PARTY_ID' // Third party ID (for third party payment method)
}

export enum VerificationDocumentStatus {
  REQUESTED = 'REQUESTED', // Document has been requested
  IN_PROGRESS = 'IN_PROGRESS', // Player has started the process of sending us the document
  REJECTED = 'REJECTED', // Document has been rejected
  APPROVED = 'APPROVED', // Document has been approved
  RECEIVED = 'RECEIVED' // Document has been received
}

export enum VerificationDocumentSendType {
  HOOYU = 'HOOYU',
  MAIL = 'MAIL'
}
