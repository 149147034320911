import React from 'react';
import loadable from '@loadable/component';
import useDownloadHtml from 'hooks/useDownloadHtml';
import LoadingElement from 'components/Loading/LoadingElement';
import FormError from 'components/FormError';
import usePublicCmsDataDynamicModal from './usePublicCmsDataDynamicModal';

const ReskinUserBox = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "ReskinUserBox" */ 'components/UserBox'),
  {
    fallback: <LoadingElement />
  }
);
const ClassicUserBox = loadable(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "ClassicUserBox" */ 'components/Preskin/UserBox'
    ),
  {
    fallback: <LoadingElement />
  }
);
const ReskinButton = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "ReskinButton" */ 'components/Button'),
  {
    fallback: <LoadingElement />
  }
);
const ClassicButton = loadable(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "ClassicButton" */ 'components/Preskin/Button'
    ),
  {
    fallback: <LoadingElement />
  }
);
const LegalDocArticle = loadable(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "LegalDocArticle" */ './src/LegalDocArticle'
    ),
  {
    fallback: <LoadingElement />
  }
);

const mapping = {
  'modal.legal-content': LegalDocArticle
};

interface DynamicModalProps {
  id: string;
  slug: string;
  close: () => void;
  isPublic: boolean;
}

const DynamicModal = ({ id, slug, close, isPublic }: DynamicModalProps) => {
  const { ref, handleDownload } = useDownloadHtml('MrQ customer data and privacy policy.html');
  const { content: data, isLoading, hasError } = usePublicCmsDataDynamicModal(slug);
  const UserBox = isPublic ? ClassicUserBox : ReskinUserBox;
  const Button = isPublic ? ClassicButton : ReskinButton;

  return (
    <UserBox id={id} title={data?.title} closeCallback={close}>
      {isLoading && <LoadingElement />}
      {hasError && <FormError errorMessage="Whoops, something went wrong..." />}
      {data && (
        <>
          <article ref={ref}>
            {data?.components.map((component) => {
              const Component = mapping[component.__component];
              return <Component key={component.id} isPublic={isPublic} {...component} />;
            })}
          </article>

          <div className="fieldset fieldset--centerflex">
            <Button
              className="submit"
              onClick={close}
              buttonText="Close"
              title="Close"
              name="close"
              id="privacyPolicy-close"
            />
            <Button
              onClick={handleDownload}
              name="download"
              id="privacyPolicy-download"
              buttonText="Download&nbsp;&nbsp;&nbsp;⇩"
              title="Download the entire document"
              className="submit terms__download"
            />
          </div>
        </>
      )}
    </UserBox>
  );
};

export default DynamicModal;
