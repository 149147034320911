import type { Middleware } from 'redux';
import effectMap from './src/effectMap';

/**
 * Works like the translator middleware but the action is passed through, and without access to dispatch for now
 * Conceptually similar to [RTK's createListenerMiddleware](https://redux-toolkit.js.org/api/createListenerMiddleware)
 */
export const effectMiddleware =
  (effects = effectMap): Middleware =>
  () =>
  (next) =>
  (action: Parameters<(typeof effects)[keyof typeof effects]>[0]) => {
    // TODO: If the effect returns a result, return it here
    effects[action?.type]?.(action);
    return next(action);
  };

export * as EffectTypes from './src/effectMap';
