import React from 'react';
import { render as reactRender } from 'react-dom';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import {
  init as sentryInit,
  withProfiler,
  thirdPartyErrorFilterIntegration,
  reactRouterV5BrowserTracingIntegration
} from '@sentry/react';
import { Identify } from '@amplitude/analytics-browser';
import amplitude from 'lib/analytics';
import loadPolyfills from 'lib/loadPolyfills';
import iOSversion from 'lib/iOSversion';
import { supportsIntersectionObserver, supportsFormData } from 'lib/featureDetect';
import * as serviceWorkerRegistration from 'lib/serviceWorkerRegistration';
import 'css/animations.css';
import 'css/transitions.css';
import _Starter from './starter';
import { history } from './store';

const isDev = __ENV__.NODE_ENV === 'development';
const isProd = __ENV__.NODE_ENV === 'production' && __ENV__.NODE_STAGE === 'production';
const perf = __ENV__.REACT_PERF === 'true';
const mocksEnabled = __ENV__.MOCKS_ENABLED === 'true';

const Starter = isProd ? withProfiler(_Starter) : _Starter;

if (navigator.userAgent.match('CriOS')) {
  // Target Chrome on iOS
  document.documentElement.classList.add('chrome-ios');
}

const iosVer = iOSversion();
// TODO: False positive of Safari when running Chrome on IOS 16.5.x
// https://mrq.atlassian.net/browse/MRQ-623
const isSafari = /^(?:(?!chrome|android).)*safari/i.test(navigator.userAgent);

if (isSafari && iosVer.version === 15) {
  document.documentElement.classList.add('safari-ios-15');
}

let loadMocks;

if (process.env.REACT_DEV) {
  if (isDev && mocksEnabled) {
    loadMocks = (async () => {
      const { worker } = await import('mocks/browser');
      worker.start({ onUnhandledRequest: 'bypass' });
    })();
  }
}

// Look in /src/README.md for snippet that should display navigator/platform information on mobile devices.

// if (debug) {
//   import('./store').then((store) => {
//     const _navigator = {};
//     // eslint-disable-next-line guard-for-in
//     for (const i in navigator) _navigator[i] = navigator[i];
//     document.addEventListener('keydown', (e) => {
//       if (e.keyCode === 27) {
//         const input = document.createElement('textarea');
//         const currentState = JSON.stringify(store.default.getState());
//         const actionLog = JSON.stringify(store.actionLog.getLog());
//         input.value = `Current state:\n${currentState}\n\nAction log:\n${actionLog}\n\nNavigator:\n${JSON.stringify(
//           _navigator
//         )}`;
//         input.style.setProperty('opacity', '0', 'important');
//         input.style.setProperty('height', '0', 'important');
//         input.style.setProperty('width', '0', 'important');
//         input.style.setProperty('position', 'fixed', 'important');
//         document.body.appendChild(input);
//         input.focus();
//         document.execCommand('selectAll');
//         document.execCommand('copy');
//         document.body.removeChild(input);
//       }
//     });
//   });
// }

if (perf) {
  // This measures component startup impact
  import('react-perf-devtool').then(({ registerObserver }) => {
    registerObserver({ timeout: 0 });
  });
}

if (!isDev) {
  disableReactDevTools();
}

const render = (Component, callback) => {
  reactRender(<Component />, document.getElementById('root'), callback);
  document.getElementById('browserslist-banner').style.display = 'none';
};

const amplitudeOptions = {
  appVersion: __ENV__.MRQ_VERSION,
  serverUrl: new URL('/workers/ampli', window.location).toString(),
  serverZone: 'EU',
  defaultTracking: {
    attribution: true,
    fileDownloads: true,
    sessions: true,
    formInteractions: true,
    pageViews: true
  }
};

if (isDev) {
  window.__dev_experiment__.finally(() => {
    // Instantiate amplitude _after_ the experiment worker has set a cookie
    amplitude.init('9a42315101fdf957a591d9795db3309e', amplitudeOptions);
  });
} else {
  amplitude.init('8c5d1ff5deabc1b0a84e1bde47841bf5', amplitudeOptions);
}

const wrapper = /MrQ App \/ (?<version>\d+(?:\.\d+(?:\.\d+)?)?)/;
const appWrapperVersion = window.navigator.userAgent?.match(wrapper)?.groups?.version;

if (appWrapperVersion) {
  const identifyEvent = new Identify();
  identifyEvent.set('App Wrapper Version', appWrapperVersion);
  amplitude.identify(identifyEvent);
}

if (!isDev) {
  sentryInit({
    integrations: [
      reactRouterV5BrowserTracingIntegration({ history }),
      thirdPartyErrorFilterIntegration({
        filterKeys: ['mrq-front-sentry'],
        behaviour: 'drop-error-if-contains-third-party-frames'
      })
    ],
    tracesSampleRate: 0.008,
    environment: __ENV__.NODE_STAGE,
    dsn: __ENV__.SENTRY_DSN,
    release: `react-mrq@${__ENV__.MRQ_VERSION}`
  });
  loadPolyfills([
    {
      test: supportsIntersectionObserver(),
      imports: () => import(/* webpackChunkName: "intersection-observer" */ 'intersection-observer')
    },
    {
      test: supportsFormData(),
      imports: () => import(/* webpackChunkName: "formdata-polyfill" */ 'formdata-polyfill')
    }
  ]).then(() => render(withProfiler(Starter)));
} else if (mocksEnabled) {
  loadMocks.finally(() => render(Starter));
} else {
  render(Starter);
}

serviceWorkerRegistration.register();
