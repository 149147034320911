import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'lib/formatters';
import { NUMBER_LIST_TYPE } from '../constants';

const playersLabel = (playerCount, gameType) => {
  switch (gameType) {
    case NUMBER_LIST_TYPE.BLACKJACK: {
      if (playerCount <= 0) return 'No open seats';
      else if (playerCount === 1) return '1 open seat';
      else return `${playerCount} open seats`;
    }
    default: {
      if (playerCount <= 0) return 'No players';
      else if (playerCount === 1) return '1 player';
      else return `${playerCount} players`;
    }
  }
};

const BottomTileInfo = ({ title, provider, playerCount, gameType, minBet }) => (
  <div className="tileInfo">
    <h3 className="tileInfo__title">{title}</h3>
    <p className="tileInfo__details">
      {provider && <span className="provider">{provider}</span>}
      {Number.isInteger(playerCount) && (
        <span className="playersCount">{playersLabel(playerCount, gameType)}</span>
      )}
    </p>
    {Number.isFinite(minBet) && (
      <p className="tileInfo__betAmount">{`${formatCurrency(minBet)}`}</p>
    )}
  </div>
);

BottomTileInfo.propTypes = {
  title: PropTypes.string,
  provider: PropTypes.string,
  playerCount: PropTypes.number,
  gameType: PropTypes.oneOf(Object.values(NUMBER_LIST_TYPE)),
  minBet: PropTypes.number
};

BottomTileInfo.defaultProps = {
  title: null,
  provider: null,
  playerCount: null,
  gameType: null,
  minBet: null
};

export default BottomTileInfo;
