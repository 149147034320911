import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import innerHeight from 'ios-inner-height';
import { setUser } from '@sentry/react';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import Api from 'services/Api';
import Navbar from 'components/Navbar';
import ModalOverlay from 'components/Modals/ModalOverlay';
import GlobalModals from 'components/Modals/GlobalModals';
import GlobalNotifications from 'components/Notifications';
import Auth from 'modules/Auth';
import Analytics from 'modules/Analytics';
import Modals from 'modules/Modals';
import User from 'modules/User';
import Wallet from 'modules/Wallet';
import Dialog from 'modules/Dialog';
import AppRoutes from 'components/AppRoutes';
import PWAModal from 'components/PWAModal';
import Triggers from 'components/Triggers';
import GlobalDialogs from 'components/Dialog/GlobalDialogs';
import DetectUserInteraction from 'components/DetectUserInteraction';
import { ShimmerInputPrimary, ShimmerInputSecondary } from 'components/Animation';
import { currencyFormatter } from 'lib/formatters';

const isDev = __ENV__.NODE_ENV === 'development';

const isSafariIos = CSS.supports('-webkit-touch-callout', 'none');

function setViewportProperty(doc) {
  let prevClientHeight;
  function handleResize() {
    const clientHeight = innerHeight();
    // Is this already cached in the library? Maybe remove
    if (clientHeight === prevClientHeight) return;
    requestAnimationFrame(() => {
      doc.style.setProperty('--vh', `${clientHeight * 0.01}px`);
      prevClientHeight = clientHeight;
    });
  }
  handleResize();
  return handleResize;
}

// Loading animations are preloaded here, so that we don't wait for them to load.
// Once we have metrics, they should be moved closer to each usage, or at least into a
// code-split route.
ShimmerInputPrimary.preload();
ShimmerInputSecondary.preload();

class App extends Component {
  componentDidMount() {
    const { kycStatus, getKyc, agreedToLatestTerms, promptKycStatus, openLatestTerms, initialize } =
      this.props;

    initialize();
    this.trackToGTM();
    // Every secure page gets KYC status if necessary
    if (kycStatus !== User.constants.kyc.PASS) {
      getKyc();
      promptKycStatus(kycStatus);
    }
    if (!agreedToLatestTerms) {
      openLatestTerms();
    }
    if (isSafariIos) {
      // Workaround for Safari iOS not reporting safe area dynamically with auto-hiding chrome
      window.addEventListener('resize', setViewportProperty(document.documentElement));
      setViewportProperty(document.documentElement);
    }
  }

  componentDidUpdate({
    location: prevLocation,
    userRef: prevUserRef,
    kycStatus: prevKycStatus,
    agreedToLatestTerms: prevAgreedToLatestTerms
  }) {
    const {
      kycStatus,
      username,
      location,
      getKyc,
      userRef,
      email,
      closeKycPrompt,
      passKycStatus,
      promptKycStatus,
      agreedToLatestTerms,
      openLatestTerms
    } = this.props;

    if (prevUserRef !== userRef && userRef) {
      this.trackToGTM();
      if (!isDev) {
        setUser({ email, username, id: userRef });
      }
    }

    if (
      prevLocation.pathname + prevLocation.search !== location.pathname + location.search &&
      kycStatus !== User.constants.kyc.PASS
    ) {
      getKyc();
    }

    if (prevKycStatus !== kycStatus) {
      if (prevKycStatus) {
        closeKycPrompt(prevKycStatus);
        if (kycStatus === User.constants.kyc.PASS) {
          passKycStatus();
        } else {
          promptKycStatus(kycStatus);
        }
      } else if (kycStatus !== User.constants.kyc.PASS) {
        promptKycStatus(kycStatus);
      }
    }
    if (prevAgreedToLatestTerms !== agreedToLatestTerms) {
      if (!agreedToLatestTerms) {
        openLatestTerms();
      }
    }
  }

  componentWillUnmount() {
    if (isSafariIos) {
      window.removeEventListener('resize', setViewportProperty(document.documentElement));
    }
    this.props.terminate();
  }

  trackToGTM = () => {
    const {
      username,
      userRef,
      affiliate,
      firstName,
      loggedThisWindow,
      trackLogin,
      trackProfile,
      kycStatus,
      birthDate,
      phoneVerified
    } = this.props;
    if (userRef && username && firstName) {
      // We only want to track it when we have all the data
      // `affiliate` is optional so not checking for it
      const authenticated = true;
      if (loggedThisWindow) {
        trackLogin({ userRef, username, firstName, affiliate, authenticated });
      }
      trackProfile({
        userRef,
        username,
        firstName,
        affiliate,
        authenticated,
        kycStatus,
        birthDate,
        phoneVerified: !!phoneVerified
      });
    }
  };

  render() {
    const { location } = this.props;

    return (
      <div
        className="app-root"
        // JSON.stringify is required to be used in CSS `content:`
        style={{ '--currencySymbol': JSON.stringify(currencyFormatter.symbol) }}
      >
        <ModalOverlay />
        <Navbar />
        <div className="contentContainer">
          <GlobalNotifications />
          <GlobalDialogs />
          <GlobalModals />
          <AppRoutes search={location.search} hash={location.hash} />
          <PWAModal />
          <DetectUserInteraction />
        </div>
        <div id="tooltipPortal" />
        <Triggers />
      </div>
    );
  }
}

App.propTypes = {
  username: PropTypes.string.isRequired,
  userRef: PropTypes.string,
  firstName: PropTypes.string,
  kycStatus: PropTypes.oneOf(Object.values(User.constants.kyc)),
  affiliate: PropTypes.object,
  email: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  initialize: PropTypes.func.isRequired,
  terminate: PropTypes.func.isRequired,
  getKyc: PropTypes.func.isRequired,
  passKycStatus: PropTypes.func.isRequired,
  promptKycStatus: PropTypes.func.isRequired,
  closeKycPrompt: PropTypes.func.isRequired,
  trackLogin: PropTypes.func.isRequired,
  trackProfile: PropTypes.func.isRequired,
  loggedThisWindow: PropTypes.bool.isRequired
};

App.defaultProps = {
  userRef: null,
  firstName: null,
  email: null,
  kycStatus: null,
  affiliate: null
};

const mapStateToProps = (state) => ({
  username: User.selectors.model(state).username,
  firstName: User.selectors.model(state).firstName,
  agreedToLatestTerms: User.selectors.model(state).agreedToLatestTerms,
  userRef: User.selectors.model(state).ref,
  email: User.selectors.model(state).email,
  birthDate: User.selectors.model(state).birthDate,
  phoneVerified: User.selectors.model(state).phoneVerified,
  kycStatus: User.selectors.getKycStatus(state),
  affiliate: User.selectors.model(state).affiliate,
  loggedThisWindow: Auth.selectors.get(state).loggedThisWindow
});

const mapDispatchToProps = (dispatch) => ({
  initialize: () => {
    dispatch(Wallet.actions.subscribe);
    dispatch(Dialog.actions.subscribe);
    Api.actions.wallet.updateBalance()(dispatch);
    Api.actions.user.getCurrent()(dispatch);
    Api.actions.user.getSettings()(dispatch);
    if (__ENV__.MRQ_GAMING_AUTHORITY !== 'MGA') {
      Api.actions.user.getFreeRounds()(dispatch);
    }
    // TODO: Why list methods here instead of individual components? After your part Hristijan, I may add
    // prefetching and detecting "stale" data for this request. This will be done at a higher level,
    // such as App/index. Normally though, we'd just call from individual components as needed.
    Api.actions.wallet.listPaymentProviders()(dispatch);
    Api.actions.wallet.listPaymentProvidersAvailable()(dispatch);
  },
  getKyc: () => User.actions.getKYC()(dispatch),
  passKycStatus: () =>
    dispatch(
      Modals.actions.create(`kycSuccess_PASS`, {
        notification: true,
        creationDate: Date.now(),
        message: User.constants.kycMessages.PASS_NOTIFICATION,
        pinned: true,
        blocking: false,
        dismissible: true,
        expiration: 5000,
        closeOnNavigation: false,
        severity: 1,
        destroyOnClose: true
      })
    ),
  promptKycStatus: (kycStatus) => {
    const message = User.selectors.getFailedKycNotification(kycStatus);
    if (message) {
      dispatch(
        Modals.actions.create(`kycFail_${kycStatus}`, {
          notification: true,
          creationDate: Date.now(),
          message: message,
          announcement: true,
          pinned: true,
          blocking: false,
          dismissible: false,
          closeOnNavigation: false,
          severity: kycStatus === User.constants.kyc.PENDING ? 3 : 4,
          destroyOnClose: true
        })
      );
    }
  },
  closeKycPrompt: (kycStatus) => dispatch(Modals.actions.destroy(`kycFail_${kycStatus}`)),
  terminate: () => {
    dispatch(Wallet.actions.unsubscribe);
    dispatch(Dialog.actions.unsubscribe);
  },
  openLatestTerms: () => dispatch(Modals.actions.open('latestTerms')),
  trackLogin: (user) => dispatch(Analytics.actions.trackLogin(user)),
  trackProfile: (user) => dispatch(Analytics.actions.trackProfile(user))
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(App);
