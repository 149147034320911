import React from 'react';
import { useAppSelector } from 'hooks/redux';
import Wallet from 'modules/Wallet';
import { logos, types } from 'lib/constants';
import PaymentMethodsProvidersList from './PaymentMethodsProvidersList';
import type { PaymentMethodTypeRemapKeys } from './types';
import { paymentMethodMap, paymentMethodTypeRemap } from './types';

interface PaymentMethodsProvidersListAdapterProps {
  onClickHandler: (e: any) => void;
}

// This is temporary fallback for the icons, until we complete the A/B Test.
// After that, we will get the icons from the backend
const mapTypeToIcon = (type: string) => {
  switch (type) {
    case types.PCI_PROXY:
      return logos.CARD_NEW;
    case types.BRAINTREE:
      return logos.CARD_NEW;
    case types.MOBILE:
      return logos.MOBILE_NEW;
    case types.PAYPAL:
      return logos.PAYPAL;
    case types.BANK_ACCOUNT:
      return logos.BANK_ACCOUNT_NEW;
    default:
      return null;
  }
};

const PaymentMethodsProvidersListAdapter = ({
  onClickHandler
}: PaymentMethodsProvidersListAdapterProps) => {
  const availablePaymentOptionRefs = useAppSelector(Wallet.selectors.getPossibleNewPaymentsRefs);

  const paymentProviders = useAppSelector(Wallet.selectors.getPaymentProviders);

  // TODO: FIX THIS - described in MQPV-556
  // We need to keep the original order of the payment methods. However, because of issues outlined in src/lib/constants.ts:36
  // the type needs to be remapped. this is a quick workaround, until we can refactor the payment method types and providers
  // to be more consistent.
  const options = availablePaymentOptionRefs?.map((type: PaymentMethodTypeRemapKeys) => ({
    type: paymentMethodTypeRemap[type] || type,
    title: paymentProviders[type]?.firstDepositFields.label,
    // TODO: After A/B test is done, we will get the icons from backend
    // icon: paymentProviders[type]?.firstDepositFields.icon,
    icon: mapTypeToIcon(paymentMethodTypeRemap[type] || type), // TODO: Fix me, part of MQPV-556
    tag: type === 'ECOSPEND' ? 'Recommended' : undefined, // TODO: This is good enough until we support tags on the backend
    value: paymentMethodMap[paymentMethodTypeRemap[type] || type] // This is confusing af, but there's no easy way to make it cleaner.
    // TODO: Fix me, part of MQPV-556 (described there as well)
  }));

  return <PaymentMethodsProvidersList options={options} onClickHandler={onClickHandler} />;
};

export default PaymentMethodsProvidersListAdapter;
