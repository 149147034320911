import { getExperiment } from '../analytics';

const variants = {
  CURRENT: 'current-design',
  NEW_DESIGN: 'new-design'
};

const flag = 'first-deposit-redesign';
const experimentData = getExperiment(flag);
const variant = experimentData?.key;
const experiment = experimentData?.experimentKey;
const scenarios = {
  inNewDesign: variant === variants.NEW_DESIGN
};
export default { flag, experimentData, variants, variant, experiment, scenarios };
