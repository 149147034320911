import React, { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import cc from 'classcat';
import { withSentryRouting } from '@sentry/react';
import FullscreenLoading from 'components/Loading/Fullscreen';
import ModalOverlay from 'components/Modals/ModalOverlay';
import MaintenanceNotice from 'components/MaintenanceNotice';
import { currencyFormatter } from 'lib/formatters';

// Parameterized transaction names
const SentryRoute = withSentryRouting(Route);

const PublicModals = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "publicModals" */ 'components/Preskin/Modals/PublicModals'
  )
);

const Login = loadable(
  () => import(/* webpackPreload: true, webpackChunkName: "login" */ 'components/Login'),
  {
    fallback: <FullscreenLoading />
  }
);
const ReskinSignup = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "signup" */ 'components/Signup'),
  {
    fallback: <FullscreenLoading />
  }
);
const ClassicSignup = loadable(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "classicSignup" */ 'components/Preskin/Signup'
    ),
  {
    fallback: <FullscreenLoading />
  }
);
const ReskinPublicNavbar = loadable(() =>
  import(/* webpackPreload: true, webpackChunkName: "publicNavbar" */ 'components/PublicNavbar')
);
const ClassicPublicNavbar = loadable(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "classicPublicNavbar" */ 'components/Preskin/PublicNavbar'
  )
);
const ResetPassword = loadable(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "reset-password" */ 'components/ResetPassword'
    ),
  {
    fallback: <FullscreenLoading />
  }
);

const PublicRoutes = ({ search }) => {
  const Signup = ClassicSignup;

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <SentryRoute exact path="/signup/:step" component={Signup} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Redirect exact from="/signup(\?.*)?" to={`/signup/account-details${search}`} />
      <Redirect exact from="*" to={`/login${search}`} />
    </Switch>
  );
};

const Public = ({ location: { pathname, search } }) => {
  const isClassicVariant = true;
  let PublicNavbar = null;
  if (!pathname.startsWith('/signup') || isClassicVariant === false) {
    PublicNavbar = ReskinPublicNavbar;
  } else if (isClassicVariant === true) {
    PublicNavbar = ClassicPublicNavbar;
  }

  return (
    <div
      className="app-root"
      style={{ '--currencySymbol': JSON.stringify(currencyFormatter.symbol) }}
    >
      <MaintenanceNotice pathname={pathname} />
      <ModalOverlay />
      {PublicNavbar ? <PublicNavbar search={search} pathname={pathname} /> : null}
      <div className="contentContainer contentContainer--public">
        <PublicModals />
        <PublicRoutes search={search} pathname={pathname} />
      </div>
      <div id="tooltipPortal" />
    </div>
  );
};

export default memo(Public);
