import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import './noMethodAllowed.css';

const NoMethodAllowed = ({ onClick, buttonText, description }) => (
  <form className="widthdraw__noneFound">
    <img
      className="userBox__image"
      src="/assets/images1/add-payment-method.gif"
      alt=""
      width="140"
      height="120"
      data-chromatic="ignore"
    />
    <p>{description}</p>
    <div className="fieldset fieldset--fullflex fieldset--center ">
      <Button
        className="submit"
        buttonText={buttonText}
        onClick={onClick}
        name="openDeposit"
        id="noMethodAllowed-openDeposit"
      />
    </div>
  </form>
);

NoMethodAllowed.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default NoMethodAllowed;
