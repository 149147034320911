import * as actions from './src/actions';
import * as constants from './src/constants';
import * as actionTypes from './src/actionTypes';
import * as selectors from './src/selectors';
import * as model from './src/model';

export default {
  actions,
  selectors,
  model,
  T: actionTypes.T,
  constants,
  actionTypes
};
export * as actionTypes from './src/actionTypes';
export * from './src/constants';
export * as types from './src/types';
