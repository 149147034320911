import React, { memo, useEffect, useMemo } from 'react';
import loadable from '@loadable/component';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Lobby, { type Section } from 'modules/Lobby';
import type { AccessRef } from 'lib/LobbyEntities';
import { isLoading } from 'lib/redux-utils';
import Footer from 'components/Footer';
import Builder from './src/Builder';
import DynamicLobbyContext from './src/DynamicLobbyContext';
import Skeleton, { TileSkeleton } from './src/Skeleton';
import './dynamicLobby.css';

const List = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "LobbyList" */ './src/List'),
  {
    fallback: <Skeleton />
  }
);

const SlotTile = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "LobbySlotTile" */ './src/SlotTile'),
  {
    fallback: <TileSkeleton className="dynamicLobby__skeleton-slotTile" />
  }
);

const OfferTile = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "LobbyOfferTile" */ './src/OfferTile'),
  {
    fallback: <TileSkeleton className="dynamicLobby__skeleton-offerTile" />
  }
);

const mapping = {
  List,
  SlotTile,
  OfferTile
};

interface DynamicLobbyProps {
  category: string;
  section: Section;
  isActive: boolean;
}

const DynamicLobby = ({ category, section, isActive }: DynamicLobbyProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) =>
    isLoading(state, Lobby.actionTypes.AT.GET_CATEGORY_CONTENT._)
  );
  const root = useAppSelector((state) =>
    Lobby.selectors.getEntityByKey(state, `${section}__${category}__root`)
  ) as AccessRef | null;

  useEffect(() => {
    if (!isActive) {
      return;
    }
    void Lobby.actions.getCategoryContent({
      sort: 'position,ASC',
      offset: 0,
      limit: 24,
      section,
      category
    })(dispatch);
  }, [dispatch, section, category, isActive]);

  const contextValue = useMemo(
    () => ({
      section,
      category
    }),
    [section, category]
  );

  const accessRef = root?.__access;

  return (
    <div className="dynamicLobby">
      {(loading || !isActive) && !accessRef && <Skeleton />}
      <div className="dynamicLobby__content">
        <DynamicLobbyContext.Provider value={contextValue}>
          {accessRef && <Builder mapping={mapping} accessRef={accessRef} />}
        </DynamicLobbyContext.Provider>
      </div>
      <Footer />
    </div>
  );
};

export default memo(DynamicLobby);
