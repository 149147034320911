import React from 'react';
import { MagicMove } from 'components/Transitions';
import { DepositLimitBase } from 'components/DepositLimit';
import { abFirstDepositRedesign } from 'lib/experiments';
import LimitsToggler from '../LimitsToggler';
import LimitsLabel from './components/LimitsLabel';

interface SetLimitsProps {
  onDoneDepositLimit: () => void;
}

const SetLimits = ({ onDoneDepositLimit }: SetLimitsProps) => {
  const [showLimits, setShowLimits] = React.useState(false);
  const [limitsCompleted, setLimitsCompleted] = React.useState(false);
  const onShow = () => setShowLimits(true);
  const handleLater = () => {
    setLimitsCompleted(true);
    onDoneDepositLimit();
  };
  const handleLimitDone = async (request: Promise<any>) => {
    // The request is passed from DepositLimitBase and we are awaiting it here before going to the next step.
    // TODO: Add loading state, as we are waiting for the request to finish before going to the next step.
    setLimitsCompleted(true);
    await request;
    onDoneDepositLimit();
  };

  const depositLimitDropdowns = (
    <DepositLimitBase
      // @ts-ignore -- Until we refactor DepositLimitBase to TS
      onDone={handleLimitDone}
      noSubmitInitial={false}
    />
  );

  const current = showLimits ? (
    depositLimitDropdowns
  ) : (
    <LimitsToggler onLater={handleLater} onShow={onShow} />
  );

  const newVariant = (
    <>
      <LimitsLabel limitsSaved={limitsCompleted} onShow={onShow} showLimits={showLimits} />
      {!limitsCompleted && showLimits && depositLimitDropdowns}
    </>
  );
  return (
    <>
      <MagicMove
        className="depositLimit collapsible"
        childKey={showLimits ? 'showLimits' : 'showToggle'}
      >
        {abFirstDepositRedesign.scenarios.inNewDesign
          ? newVariant
          : limitsCompleted
          ? null
          : current}
      </MagicMove>
      {!abFirstDepositRedesign.scenarios.inNewDesign && limitsCompleted ? null : <hr />}
    </>
  );
};

export default SetLimits;
